import React from "react";
import SmallSpinner from "../../components/SmallSpinner";
import DataGridRowCollection from "../data-grid/data-grid-row-collection";

const GridContext = React.createContext({}) as any;

interface DataGridProps {
  ColumnArray: [];
  SelectAPI: string;
  UpdateAPI?: string | undefined;
  DeleteAPI?: string;
  MaxNumberOfRowsPerPage: number;
  GridName: string;
  OnDataSaved?: any;
  PrimaryKeyName: string;
  SortByColumn: string;
  LoadOnScroll: boolean;
}

interface DataGridState {
  renderCount: number;
  isLoading: boolean;
}

class DataGrid extends React.Component<DataGridProps, DataGridState> {
  constructor(props: DataGridProps) {
    super(props);
    this.state = {
      renderCount: 0,
      isLoading: true,
    };
    this.updateKeyValue = this.updateKeyValue.bind(this);
    this.handleDataSaved = this.handleDataSaved.bind(this);
    this.handleLoadingState = this.handleLoadingState.bind(this);
  }
  //called when children click Cancel
  //incrementing the renderCount will force child components to reload
  updateKeyValue(): void {
    this.setState({
      renderCount: this.state.renderCount + 1,
    });
  }
  handleDataSaved(data: any) {
    //send the saved data out to the calling component
    //for saving and bespoke operations
    if (
      this.props.OnDataSaved !== null &&
      this.props.OnDataSaved !== undefined
    ) {
      this.props.OnDataSaved(data);
    }
  }
  handleLoadingState(loadingState: boolean) {
    this.setState({
      isLoading: loadingState,
    });
  }
  render() {
    const contextValue = {
      updateKeyValue: this.updateKeyValue,
    };
    return (
      <>
        <div className="col-md-12 spinnerCell">
          <SmallSpinner
            isLoading={this.state.isLoading}
            actionText="Please Wait"
          />
        </div>
        <GridContext.Provider value={contextValue}>
          <DataGridRowCollection
            key={"DataGridRowCollection_" + this.state.renderCount}
            ColumnArray={this.props.ColumnArray}
            SelectAPI={this.props.SelectAPI}
            UpdateAPI={this.props.UpdateAPI}
            DeleteAPI={this.props.DeleteAPI}
            MaxNumberOfRowsPerPage={this.props.MaxNumberOfRowsPerPage}
            GridName={this.props.GridName}
            OnDataSaved={this.handleDataSaved}
            OnLoadingState={this.handleLoadingState}
            OnUpdateKeyValue={this.updateKeyValue}
            PrimaryKeyName={this.props.PrimaryKeyName}
            SortByColumn={this.props.SortByColumn}
            LoadOnScroll={this.props.LoadOnScroll}
          />
        </GridContext.Provider>
      </>
    );
  }
}

export default DataGrid;
