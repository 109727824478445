import React from "react";
import DataGrid from "../components/data-grid/data-grid";
import { Redirect } from "../utilities/utilities";
import { render } from "@testing-library/react";
import { Route, Link } from "react-router-dom";
import DntList from "./dnt-list";
import Package from "../components/importpackage";

interface IPackageHistoryProps {}
interface IPackageHistoryState {
  renderCount: number;
  redirect: boolean;
}

class PackageHistory extends React.Component<
  IPackageHistoryProps,
  IPackageHistoryState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      renderCount: 0,
      redirect: false,
    };
    this.buildColumnList = this.buildColumnList.bind(this);
  }

  buildColumnList(): any {
    const cols = [
      {
        DataPropertyName: "SmartlingJobName",
        ColumnAliasName: "Job Name",
        InputType: "text",
        DataType: "string",
        ShowColumn: true,
      },
      {
        DataPropertyName: "PackageId",
        ColumnAliasName: "Package Id",
        InputType: "text",
        ShowColumn: true,
        IsLink: true,
        IsIdColumn: true,
        APIPath: "",
        QueryStringParams: ["PackageId"],
      },
      {
        DataPropertyName: "CreatedDate",
        ColumnAliasName: "Date Created",
        InputType: "text",
        DataType: "datetime",
        ShowColumn: true,
      },
      {
        DataPropertyName: "SmartlingJobStatus",
        ColumnAliasName: "Smartling Job Status",
        InputType: "text",
        DataType: "bool",
        ShowColumn: true,
      },
    ];
    return cols;
  }
  render() {
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Package History</h3>
            <div className="row">
              <div className="col-md-12">
                <DataGrid
                  key={"dataGrid_" + this.state.renderCount}
                  ColumnArray={this.buildColumnList()}
                  SelectAPI="ImportFileService/getpackagehistories"
                  GridName="PackageHistory"
                  MaxNumberOfRowsPerPage={50}
                  SortByColumn="CreatedDate"
                  PrimaryKeyName="PackageId"
                  LoadOnScroll={false}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageHistory;
