import React from "react";
import { AxiosError } from "axios";
import { MakeRequest } from "../utilities/data-util";
import { sanitizeInput, Validator } from "../validation/validator";
import ValidationSummary from "../validation/validation-summary";
import {
  getAllFormElements,
  showValidationErrors,
} from "../utilities/utilities";
import { setToaster } from "../components/toaster/Toaster";
import { ToastContainer } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import Checkbox from "../components/checkbox/Checkbox";
import SmallSpinner from "../components/SmallSpinner";

interface IAddSupportedCultureProps {}

interface IAddSupportedCultureState {
  cultureName: string;
  cultureId: number;
  cultureCode: string;
  smartlingCultureCode: string;
  isFullySupported: boolean;
  formErrors: any;
  doingWork: boolean;
}

class AddSupportedCulture extends React.Component<
  IAddSupportedCultureProps,
  IAddSupportedCultureState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      cultureName: "",
      cultureId: 0,
      cultureCode: "",
      smartlingCultureCode: "",
      isFullySupported: false,
      formErrors: [],
      doingWork: false,
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  postData(data: any): void {
    const successCallback = (result: any): void => {
      if (result === "") {
        setToaster({
          state: { success: true },
          message: "Success: Culture Added",
        });
        this.setState({
          cultureName: "",
          cultureId: 0,
          cultureCode: "",
          smartlingCultureCode: "",
          isFullySupported: false,
          doingWork: false,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { success: false },
            message:
              "Failed: Could not add culture: Response status " +
              error.response.status,
          });
        } else {
          setToaster({
            state: { success: false },
            message: "Failed: Could not add culture: Unknown server error",
          });
        }
        this.setState({
          doingWork: false,
        });
      }
    };
    MakeRequest({
      url: "SupportedCultureService",
      method: "post",
      data: { postData: data },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  handleSubmit = (e: any) => {
    if (e !== null && e !== undefined) {
      e.preventDefault();
      const cultureName = this.state.cultureName.trim();
      const cultureId = this.state.cultureId;
      const cultureCode = this.state.cultureCode.trim();
      const smartlingCultureCode = this.state.smartlingCultureCode.trim();
      const isFullySupported = this.state.isFullySupported;

      let form = document.getElementById("addSupportedApp");
      let allFormElements = getAllFormElements({ element: form ? form : e });
      let errors = Validator({ fields: allFormElements });

      if (errors.length === 0) {
        this.postData({
          cultureName: cultureName,
          cultureId: cultureId,
          cultureCode: cultureCode,
          smartlingCultureCode: smartlingCultureCode,
          isFullySupported: isFullySupported,
        });
        //this.updateChild({ Name: name, ChildId: this.props.ChildId, TokensTotal: totalTokens });
        this.setState({ formErrors: [], doingWork: true });
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  };

  handleTextChange(e: any) {
    if (e !== null && e !== undefined) {
      let value = sanitizeInput(e.target.value, e.target.type);

      switch (e.target.id) {
        case "cultureNameInput":
          this.setState({ cultureName: value });
          break;
        case "cultureCodeInput":
          this.setState({ cultureCode: value });
          break;
        case "smartlingCultureCode":
          this.setState({ smartlingCultureCode: value });
          break;
        case "addCultureIdInput":
          this.setState({ cultureId: parseInt(value) });
          break;
      }

      //is this a required field
      let errors = Validator({ fields: e.target });
      if (errors.length === 0) {
        this.setState({ formErrors: [] });
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  }
  handleCheckBoxChange(e: any) {
    if (e !== null && e !== undefined) {
      this.setState({ isFullySupported: e.target.checked }, () => {});
    }
  }

  render() {
    return (
      <div className="section-light-table">
        <div className="container">
          <h3 className="title">Add Supported Culture</h3>
          <div className="row">
            <div className="col-md-12 spinnerCell">
              <SmallSpinner
                isLoading={this.state.doingWork}
                actionText="Please Wait"
              />
            </div>
            <ValidationSummary Errors={this.state.formErrors} />
            <form
              id="addSupportedApp"
              onSubmit={this.handleSubmit}
              className=" input-form"
            >
              <div className="mb-3">
                <label
                  htmlFor="cultureNameInput"
                  id="cultureNameLabel"
                  className="form-label"
                >
                  Culture Name:
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="cultureNameInput"
                  value={this.state.cultureName}
                  onChange={this.handleTextChange}
                  data-required={true}
                  data-id="cultureNameInput"
                  data-validationname="Culture name"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="cultureCodeInput"
                  id="cultureCodeLabel"
                  className="form-label"
                >
                  Culture Code:
                </label>
                <input
                  type="test"
                  autoComplete="off"
                  className="form-control"
                  id="cultureCodeInput"
                  value={this.state.cultureCode}
                  onChange={this.handleTextChange}
                  data-required={true}
                  data-id="cultureCodeInput"
                  data-validationname="Culture code"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="smartlingCultureCodeInput"
                  id="smartlingCultureCodeLabel"
                  className="form-label"
                >
                  Smartling Culture Code:
                </label>
                <input
                  type="test"
                  autoComplete="off"
                  className="form-control"
                  id="smartlingCultureCode"
                  value={this.state.smartlingCultureCode}
                  onChange={this.handleTextChange}
                  data-required={true}
                  data-id="smartlingCultureCode"
                  data-validationname="Smartling Culture code"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="addCultureIdInput"
                  id="cultureIdLabel"
                  className="form-label"
                >
                  CultureId:
                </label>
                <input
                  type="number"
                  pattern="[0-9]{0,5}"
                  autoComplete="off"
                  className="form-control"
                  id="addCultureIdInput"
                  value={this.state.cultureId}
                  onChange={this.handleTextChange}
                  data-required={true}
                  data-id="addCultureIdInput"
                  data-validationname="Culture Id"
                  data-min="1"
                />
              </div>
              <div className="mb-3">
                <div className="form-group">
                  <Checkbox
                    id="chkFullySupported"
                    label="Is Fully Supported"
                    checked={this.state.isFullySupported}
                    onSelecting={this.handleCheckBoxChange}
                    datarequired={false}
                    labelClass="label-dark"
                    data-validationname="Item Checkbox"
                  />
                </div>
              </div>
              <button
                type={"submit"}
                id="btnAddCulture"
                className="btn btn-success btn-round"
              >
                Add Culture
              </button>
            </form>
          </div>
          <ToastContainer autoClose={2000} position="bottom-right" />
        </div>
      </div>
    );
  }
}

export default AddSupportedCulture;
