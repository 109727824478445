import React from "react";
import { AxiosError } from "axios";
import { useState, useEffect, useCallback } from "react";
import Menu from "./menu";
import SecureMenu from "./secure-menu";
import {
  SetTokenStatus,
  GetTokenFromLocalStorage,
  GetUserFromToken,
} from "../account/authentication-context";

interface Props {
  currentNav: string;
}

interface State {
  user?: any;
  userCallback?: any;
}

let currentUserState: any = {};
const userContext = React.createContext(currentUserState) as any;

const MenuPicker = (props: Props): any => {
  const userCallback = useCallback(
    (loggedInUser: any) => setState({ user: loggedInUser, userCallback }),
    []
  );
  const [state, setState] = useState({
    user: currentUserState,
    userCallback,
  });
  //if page is refreshed we need to check the token status
  //and set the initial value of the menu
  SetTokenStatus();
  let token = GetTokenFromLocalStorage();

  useEffect(() => {
    if (
      Object.keys(state.user).length === 0 &&
      token !== null &&
      token !== undefined
    ) {
      getUser();
    } else {
      return;
    }
  }, [state]);

  const getUser = () => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (
        result &&
        result !== undefined &&
        result.Data &&
        result.Data !== undefined
      ) {
        //do logic
        if (result.Success === true) {
          userCallback(result.Data);
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
      }
    };
    GetUserFromToken({
      url: "Authentication/GetUser",
      data: {},
      successCallback,
      errorCallback,
    });
  };

  if (token !== null && token !== undefined && token !== "") {
    switch (props.currentNav) {
      case "LoggedIn":
        return (
          <userContext.Provider value={state}>
            <SecureMenu User={state} />
          </userContext.Provider>
        );
      case "LoggedOut":
        return (
          <userContext.Provider value={state}>
            <Menu />
          </userContext.Provider>
        );
    }
  }
  return (
    <userContext.Provider value={state}>
      <Menu />
    </userContext.Provider>
  );
};

export { userContext };
export default MenuPicker;
