import React from "react";
import {
  faArrowLeft,
  faArrowRight,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

function HomeRoute(): any {
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div id="home">
      <div className="section-light-top-level">
        <div className="row">
          <div className="col-md-4">
            <div className="info">
              <div className="icon icon-default">
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
              </div>
              <div className="description">
                <h4 id="export" className="info-title">
                  Export
                </h4>
                <p id="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="info">
              <div className="icon icon-default">
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
              </div>
              <div className="description">
                <h4 className="info-title">Import</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="info">
              <div className="icon icon-default">
                <FontAwesomeIcon icon={faFlag} size="lg" />
              </div>
              <div className="description">
                <h4 className="info-title">Cultures</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-light">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="info">
                <div className="description">
                  <h4 className="info-title">Export</h4>
                  <Pie data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info">
                <div className="description">
                  <h4 className="info-title">Import</h4>
                  <Doughnut data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info">
                <div className="description">
                  <h4 className="info-title">Cultures</h4>
                  <Bar data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeRoute;
