import React from "react";
import { setToaster } from "../toaster/Toaster";
import { MakeRequest } from "../../utilities/data-util";
import { AxiosError } from "axios";
import OutputItem from "./output-item";

let refreshIntervalId: any;

enum EntryType {
  "list-group-item item-info" = "Information" as any,
  "list-group-item item-error" = "Error" as any,
  "list-group-item item-warning" = "Warning" as any,
}

interface IOutputListProps {
  runExport: boolean;
  onExport: any;
  count: number;
  currentProcessId: string;
  onScroll: any;
  isCancelled: boolean;
}

interface IOutputListState {
  data: any;
  doingWork: boolean;
  noData: boolean;
  lastLogTableId: number;
}

class OutputList extends React.Component<IOutputListProps, IOutputListState> {
  constructor(props: IOutputListProps) {
    super(props);
    this.state = {
      data: [],
      doingWork: this.props.runExport,
      noData: false,
      lastLogTableId: 0,
    };
  }

  async loadData(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result && result.LogEntries && result.LogEntries.length > 0) {
        if (this.state.data.length === 0) {
          this.setState(
            {
              data: result.LogEntries,
              noData: false,
              lastLogTableId:
                result.LogEntries[result.LogEntries.length - 1].LogTableId,
            },
            () => {
              this.props.onExport(this.state.doingWork);
            }
          );
        } else {
          let messages = this.state.data;
          result.LogEntries.forEach((entry: any) => {
            messages.push(entry);
          });
          this.setState(
            {
              data: messages,
              lastLogTableId:
                result.LogEntries[result.LogEntries.length - 1].LogTableId,
              doingWork: result.OperationComplete ? false : true,
            },
            () => {
              this.props.onScroll();
              if (result.OperationComplete) {
                this.props.onExport(this.state.doingWork);
              }
            }
          );
        }
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        let errorEntry = {
          LogTableId: 0,
          Message: "Error:" + error ? error.message : "Unknown Error",
          ProcessId: this.props.currentProcessId,
          PackageId: "00000000-0000-0000-0000-000000000000",
          LastModifiedTimestamp: new Date(),
          LogLevel: "Error",
        };
        let messages = this.state.data;
        messages.push(errorEntry);

        this.setState(
          { data: messages, doingWork: false, noData: true },
          () => {
            setToaster({
              state: { error: true },
              message: error.response ? error.message : "Unknown Error",
            });
            this.props.onExport(this.state.doingWork);
            this.props.onScroll();
          }
        );
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url:
        "Commonservice/GetLogMessages/" +
        this.state.lastLogTableId +
        "/" +
        this.props.currentProcessId,
      method: "get",
      data: {},
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  async cancel(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result && result.LogEntries && result.LogEntries.length > 0) {
        this.setState({
          data: result.LogEntries,
          noData: false,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
      }
    };
    MakeRequest({
      url: "ExportFileService",
      method: "delete",
      data: { querystringParams: "?id=" + this.props.currentProcessId },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  pollDatabase = (): any => {
    refreshIntervalId = setInterval(() => {
      if (this.props.runExport === true && this.state.doingWork === true) {
        this.loadData();
      } else {
        this.setState({ doingWork: false }, () => {
          this.props.onExport(this.state.doingWork);
          clearInterval(refreshIntervalId);
        });
      }
    }, 10000);
  };
  componentDidMount() {
    if (this.props.runExport) {
      this.setState({ doingWork: true }, () => {
        this.loadData();
        this.pollDatabase();
      });
    }
    if (this.props.isCancelled) {
      if (refreshIntervalId !== null && refreshIntervalId !== undefined) {
        this.cancel();
        clearInterval(refreshIntervalId);
      }
    }
  }
  render() {
    if (this.state.data != null && this.state.data.length > 0) {
      const logEntries = this.state.data.map(
        (entry: any) =>
          entry.Message &&
          entry.Message !== undefined && (
            <OutputItem
              key={entry.LogTableId}
              value={entry.Message}
              className={EntryType[entry.LogLevel]}
              level={entry.LogLevel}
            />
          )
      );
      //get the last item
      /*let lastEntry = logEntries.at(-1);
      if (lastEntry && lastEntry.LogTableId) {
        this.setState({ lastLogTableId: lastEntry.LogTableId });
      }*/
      return <ul className="list-group list-group-flush">{logEntries}</ul>;
    } else {
      return <></>;
    }
  }
}
export default OutputList;
