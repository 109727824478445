import React from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "../../dayforce.png";

interface Props {
  User: any;
}

const SecureMenu = (props: Props) => {
  return (
    <div>
      <div className="section">
        <nav className="navbar navbar-expand-lg fixed-top nav-down">
          <div className="container">
            <div className="navbar-translate">
              <Link className="navbar-brand" to={`/`} data-placement="bottom">
                <span>
                  <img
                    src={logo}
                    width={161}
                    height={45}
                    alt="child-reaching"
                  />
                </span>
              </Link>
              <a
                className="navbar-brand"
                href="/"
                rel="tooltip"
                title="@Resources.Menu.Menu_Title"
                data-placement="bottom"
              >
                <h6>Globalization</h6>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse"
              data-nav-image="#"
              data-color="orange"
            >
              <ul className="navbar-nav ml-auto">
                <li className="dropdown nav-item">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Export
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-danger">
                    <Link className="dropdown-item" to={`defaultexport`}>
                      Default Export
                    </Link>
                    <Link className="dropdown-item" to={`customexport`}>
                      Custom Export
                    </Link>
                  </div>
                </li>
                <li className="dropdown nav-item">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Import
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-danger">
                    <Link className="dropdown-item" to={`sqlfiles`}>
                      SQL Files
                    </Link>
                  </div>
                </li>
                <li className="dropdown nav-item">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cultures
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-danger">
                    <Link className="dropdown-item" to={`supportedcultures`}>
                      Supported Cultures
                    </Link>
                    <Link className="dropdown-item" to={`addsupportedculture`}>
                      Add Supported Culture
                    </Link>
                  </div>
                </li>
                <li className="dropdown nav-item">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Apps
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-danger">
                    <Link className="dropdown-item" to={`supportedapps`}>
                      Supported Apps
                    </Link>
                    <Link className="dropdown-item" to={`addsupportedapp`}>
                      Add Supported App
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`settings`}>
                    Settings
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="section">
        <div id="detail" className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SecureMenu;
