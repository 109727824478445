import React from "react";
import { setToaster } from "../components/toaster/Toaster";
import { ToastContainer } from "react-toastify";
import { MakeRequest } from "../utilities/data-util";
import { AxiosError } from "axios";
import SmallSpinner from "../components/SmallSpinner";
import { Validator } from "../validation/validator";
import ValidationSummary from "../validation/validation-summary";
import {
  getAllFormElements,
  showValidationErrors,
} from "../utilities/utilities";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { Popover } from "../components/pop-over/pop-over";
import { sanitizeInput } from "../validation/validator";

interface Props {}
interface State {
  data: any;
  renderCount: number;
  noData: boolean;
  processId: string;
  formErrors: any;
  supportedCultures: any;
  selectedCultures: string[];
  fileUri: string;
}
class Import extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      renderCount: 0,
      noData: false,
      processId: "",
      formErrors: [],
      supportedCultures: [],
      selectedCultures: [],
      fileUri: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormKeyDown = this.handleFormKeyDown.bind(this);
    this.handleOnCultureChange = this.handleOnCultureChange.bind(this);
    this.handleFileNameChange = this.handleFileNameChange.bind(this);
  }

  async getSupportedCultures(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({
          supportedCultures: result,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "SupportedCultureService/getsupportedcultures",
      method: "get",
      data: {},
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }

  async postData(formData: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({
          data: result,
          processId: result.ProcessId,
          noData: false,
          renderCount: this.state.renderCount + 1,
        });
        setToaster({
          state: { success: true },
          message: "Success: File Imported",
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ noData: true }, () => {
          setToaster({
            state: { error: true },
            message: error.response
              ? error.message
              : "Unknown Error: Check DTM Service",
          });
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "ImportFileService",
      method: "get",
      data: {
        querystringParams:
          "?fileUri=" +
          formData.fileUri +
          "&publishedStatus=" +
          formData.publishedStatus +
          "&locale=" +
          formData.locale,
      },
      successCallback: successCallback,
      errorCallback: errorCallback,
      isFormData: false,
    });
  }
  handleFormKeyDown = (e: any) => {
    // Prevent form submission when Enter key is pressed,
    // unless focused form element type is "submit" or "reset"
    const targetType = e.target.type;
    const enterKeyPressed = e.code === "Enter";
    if (enterKeyPressed && targetType !== "submit" && targetType !== "reset") {
      e.preventDefault();
    }
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (e !== null && e !== undefined) {
      let form = document.getElementById("importForm");
      let allFormElements = getAllFormElements({ element: form ? form : e });
      let errors = Validator({ fields: allFormElements });

      if (errors.length === 0) {
        //do we have any values
        if (
          this.state.selectedCultures.length > 0 &&
          this.state.fileUri !== ""
        ) {
          this.state.selectedCultures.forEach((culture: any) => {
            let fileUri: string = this.state.fileUri.trim();
            let data: any = {
              fileUri: fileUri,
              publishedStatus: "published",
              locale: culture.value,
            };

            this.postData(data);
          });
        }
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  };

  scrollOutput = () => {
    let outputBoxRef = document.getElementById("outputBox");
    if (outputBoxRef && outputBoxRef !== undefined) {
      outputBoxRef.scrollTop = outputBoxRef.scrollHeight + 10;
    }
  };
  handleOnCultureChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ selectedCultures: e });
    }
  };

  handleFileNameChange = (e: any) => {
    if (e !== null && e !== undefined) {
      let value = sanitizeInput(e.target.value, e.target.type);
      this.setState({ fileUri: value });
    }
  };

  componentDidMount = () => {
    this.getSupportedCultures();
  };
  render() {
    let cultureOptions: any = [];
    this.state.supportedCultures.forEach((option: any) => {
      cultureOptions.push({
        value: option.SmartlingCultureCode.toString(),
        label:
          option.CultureCode.toString() + " " + option.CultureName.toString(),
      });
    });
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Import</h3>
            <div className="row">
              <form
                id="importForm"
                onKeyDown={this.handleFormKeyDown}
                onSubmit={this.handleSubmit}
                className="row g-3"
              >
                <div className="col-12">
                  <ValidationSummary Errors={this.state.formErrors} />
                  <h5>Parameters</h5>
                  <div className="col-12 formElementContainer background-culture">
                    <label
                      htmlFor="drpCultures"
                      id="cultures"
                      className="form-label"
                    >
                      Cultures:
                    </label>
                    <Popover
                      title="Cultures"
                      refElement="cultures"
                      popElement="cultures-text"
                      popoverContent="You can select multiple cultures. To find a culture, select the dropdown and start typing it's code. Leaving the cultures dropdown empty means that all cultures will be imported"
                    />
                    <Select
                      id="cultures"
                      defaultValue={[cultureOptions[0], cultureOptions[1]]}
                      isMulti
                      name="colors"
                      options={cultureOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleOnCultureChange}
                      value={this.state.selectedCultures}
                    />
                  </div>
                  <div className="col-12 formElementContainer background-search">
                    <label
                      htmlFor="query"
                      id="lblFileName"
                      className="form-label"
                    >
                      File Name:
                    </label>
                    <Popover
                      title="fileName"
                      refElement="fileName"
                      popElement="fileName-text"
                      popoverContent="The full file name including the extension e.g. '2f2cb9f1-7f77-4a22-9c0e-1057e0e7710b_0.json'"
                    />
                    <input
                      id="lblFileName"
                      type="text"
                      className="form-control"
                      onChange={this.handleFileNameChange}
                      value={this.state.fileUri}
                      data-required="true"
                      data-validationname="File Name"
                      data-id="FileName"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <button
                    type="submit"
                    className="btn btn-success btn-round btn-left"
                    disabled={
                      !(
                        this.state.selectedCultures.length > 0 ||
                        this.state.fileUri !== ""
                      )
                    }
                  >
                    Start Import
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={4000} position="bottom-right" />
      </>
    );
  }
}
export default Import;
