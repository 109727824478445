import { Prerequisite, MakeRequest } from "../../utilities/data-util";
import { UserProps } from "../../components/icomponents";

export const GetTokenFromLocalStorage = (): any => {
  let userToken = localStorage.getItem("auth_token_globalization");
  if (userToken) {
    return JSON.parse(userToken);
  }
  return null;
};

export const AddTokenToLocalStorage = (tokenObj: any): void => {
  let userToken = localStorage.getItem("auth_token_globalization");
  if (userToken) {
    localStorage.removeItem("auth_token_globalization");
    localStorage.setItem("auth_token_globalization", JSON.stringify(tokenObj));
  } else {
    localStorage.setItem("auth_token_globalization", JSON.stringify(tokenObj));
  }
};

export const RemoveTokenFromLocalStorage = (): any => {
  let userToken = localStorage.getItem("auth_token_globalization");
  if (userToken) {
    localStorage.removeItem("auth_token_globalization");
  }
};

export async function InvokeApi(callback: any): Promise<any> {
  try {
    let result = await CheckTokenStatus();
    return new Promise((resolve: any) => {
      if (result !== null && result.Success) {
        callback();
        return resolve(true);
      } else {
        RemoveTokenFromLocalStorage();
        return resolve(false);
      }
    });
  } catch {
    return new Promise((resolve: any) => {
      RemoveTokenFromLocalStorage();
      return resolve(false);
    });
  }
}

export async function SetTokenStatus(): Promise<any> {
  try {
    return await CheckTokenStatus();
  } catch (error: any) {
    RemoveTokenFromLocalStorage();
    return "";
  }
}

function CheckTokenStatus(): any {
  let url: string = "CommonService/heartbeat";
  let userToken = localStorage.getItem("auth_token_globalization");
  if (userToken && userToken !== "") {
    return new Promise((resolve: any, reject: any) => {
      Prerequisite(
        url,
        (successResponse: any) => {
          resolve(successResponse);
        },
        (errorResponse: any) => {
          reject(errorResponse);
        }
      );
    });
  } else {
    return null;
  }
}

export async function GetUserFromToken(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: {},
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function updateUserAccount(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "put",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function deleteUserAccount(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "delete",
    data: { querystringParams: "?id=" + props.userId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}
