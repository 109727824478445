import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  state: { success?: boolean; error?: boolean; warn?: boolean; info?: boolean };
  message: string;
}
export const setToaster = (props: Props): any => {
  if (props.state.success != null && props.state.success === true) {
    toast.success(props.message);
    return;
  } else if (props.state.error != null && props.state.error === true) {
    toast.error(props.message);
    return;
  } else if (props.state.warn != null && props.state.warn === true) {
    toast.warn(props.message);
  } else if (props.state.info != null && props.state.info === true) {
    toast.info(props.message);
    return;
  }

  toast(props.message);
  return;
};
