import { useEffect, useState} from "react";
import { setToaster } from "../components/toaster/Toaster";
import { AxiosError } from "axios";
import { MakeRequest } from "../utilities/data-util";
import { useParams } from "react-router-dom";
import "./package.css"

interface PackageHistory{
    PackageHistoryId: number
    PackageId: String
    ImportStatus: boolean
    ExportStatus: boolean
    SupportedAppId: number
    SupportedAppName: String
    CreatedDate: Date
    SmartlingJobName: String
    SmartlingJobUid: String
    SmartlingJobStatus: boolean
    JiraTicket: string
    PullRequest: string
    Locales: String
    RecievedLocales: number
    TotalLocales: number
    Tests: String
}

interface PackageLogs{
    Message:String
    LogLevel:String
    LastModifiedTimestamp:Date
}

const Package = () =>{
    const {packageId} = useParams()
    const [packageHistory,setPackageHistory] = useState<PackageHistory>({
        PackageHistoryId:-1,
        PackageId:"",
        ImportStatus:false,
        ExportStatus:false,
        SupportedAppId:-1,
        SupportedAppName:"",
        CreatedDate: new Date(),
        SmartlingJobName: "",
        SmartlingJobUid: "",
        SmartlingJobStatus: false,
        JiraTicket: "",
        PullRequest: "",
        Locales: "",
        RecievedLocales: -1,
        TotalLocales: -1,
        Tests: ""
    })
    const [packageLogs, setPackageLogs] = useState<PackageLogs[]>([
        {
            Message:"",
            LogLevel:"",
            LastModifiedTimestamp: new Date()
        }
    ])

    useEffect(()=>{
        fetchPackageHistory()
        fetchPackageLogs()
    },[])

    const downloadImportFile = () =>{
        const successCallback = (result: any): void => {
            
          };
          const errorCallback = (error: AxiosError): void => {
            if (error) {
              setToaster({
                state: { error: true },
                message: error.response
                  ? error.message
                  : "Unknown Error: Check DTM Service",
              });
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
              }
            }
          };
        MakeRequest({
            url: "ImportFileService/DownloadImportFile",
            method: "get",
            data: {
                querystringParams:
                "/" + packageId,
            },
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
    }

    const fetchPackageHistory = () =>{
        const successCallback = (result: any): void => {
            if (result) {
                setPackageHistory(result)
            }
          };
          const errorCallback = (error: AxiosError): void => {
            if (error) {
              setToaster({
                state: { error: true },
                message: error.response
                  ? error.message
                  : "Unknown Error: Check DTM Service",
              });
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
              }
            }
          };
        MakeRequest({
            url: "ImportFileService/getpackagehistory",
            method: "get",
            data: {
                querystringParams:
                "/" + packageId,
            },
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
    }

    const fetchPackageLogs = () =>{
        const successCallback = (result: any): void => {
            if (result) {
                setPackageLogs(result)
            }
          };
          const errorCallback = (error: AxiosError): void => {
            if (error) {
              setToaster({
                state: { error: true },
                message: error.response
                  ? error.message
                  : "Unknown Error: Check DTM Service",
              });
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
              }
            }
          };
        MakeRequest({
            url: "ImportFileService/getpackagelogs",
            method: "get",
            data: {
                querystringParams:
                "/" + packageId,
            },
            successCallback: successCallback,
            errorCallback: errorCallback,
          });
    }

    return(
        <>
            <div className="container section-light-table">
                <div className="row">
                    <div className="col">
                        <p className="text-muted small">{new Date(packageHistory.CreatedDate).toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <p className="large">{packageHistory.SmartlingJobName}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                        Import Status
                        <br/>
                        {packageHistory.ImportStatus && 
                            <div className="badge-success bg-success">Imported</div>
                            ||
                            <div className="badge-alert bg-danger">Not imported</div>
                        }
                    </div>
                    <div className="col-2">
                        Export Status
                        <br/>
                        {packageHistory.ExportStatus && 
                            <div className="badge-success bg-success">Exported</div>
                            ||
                            <div className="badge-alert bg-danger">Not Exported</div>
                        }
                    </div>
                    <div className="col-2">
                        App
                        <br/>
                        {packageHistory.SupportedAppName}
                    </div>
                    <div className="col-2">
                        Smartling Status
                        <br/>
                        {packageHistory.SmartlingJobStatus && 
                            <div className="badge-success bg-success">Returned from Smartling</div>
                            ||
                            <div className="badge-warning bg-warning">In progress</div>
                        }
                    </div>
                    <div className="col-4">
                        Package ID
                        <br/>
                        {packageHistory.PackageId}
                    </div>
                </div>
                <hr/>
                    <div className="row">
                        <div className="col-5">
                            <div className="row">
                                <div className="col">
                                    Details
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col">
                                        <dl className = "row">
                                            <dt className="col-sm-4">Locales</dt>
                                            <dd className="col-sm-8"><div className="localeButton" data-toggle="modal" data-target="#localeModal">{packageHistory.TotalLocales}</div></dd>
                                            <div className="modal fade" id="localeModal" tabIndex={-1} role="dialog" aria-labelledby="localeModalTitle" aria-hidden="true">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="localeModalTitle">Locales</h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        {packageHistory.Locales}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <dt className="col-sm-4">Smartling Job</dt>
                                            <dd className="col-sm-8"><a href={"https://dashboard.smartling.com/app/projects/f99ba3d9c/account-jobs/f99ba3d9c:"+packageHistory.SmartlingJobUid}>{packageHistory.SmartlingJobUid}</a></dd>
                                            <dt className="col-sm-4">Jira Ticket</dt>
                                            <dd className="col-sm-8">
                                                {packageHistory.JiraTicket && 
                                                <a href={packageHistory.JiraTicket}>
                                                    {packageHistory.JiraTicket}
                                                </a>
                                                ||
                                                <div>
                                                    Jira Ticket not attached
                                                </div>
                                                }
                                            </dd>
                                            <dt className="col-sm-4">Pull Request</dt>
                                            <dd className="col-sm-8">
                                                {packageHistory.PullRequest && 
                                                <a href={packageHistory.PullRequest}>
                                                    {packageHistory.PullRequest}
                                                </a>
                                                ||
                                                <div>
                                                    Pull Request not created
                                                </div>
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                            </div>
                        <hr/>
                            <div className="row">
                                <div className="col">
                                    <p>Logs</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {packageLogs.map((log,index)=>{
                                        return (
                                        <samp>
                                            {index+1} | {log.LogLevel} | {new Date(log.LastModifiedTimestamp).toLocaleString()}
                                            <br/>
                                            {log.Message}
                                            <br/>
                                            <br/>
                                        </samp>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-1-sm border"/>
                        <div className="col-6">
                                <div className="row">
                                    <div className="col">
                                        Locales Recieved
                                        <div className="progress" style={{height:20}}>
                                            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:((packageHistory.RecievedLocales/packageHistory.TotalLocales)*100)+"%"}} aria-valuenow={packageHistory.RecievedLocales} aria-valuemin={0} aria-valuemax={packageHistory.TotalLocales}><div style={{color:"black", fontWeight:"600"}}>{packageHistory.RecievedLocales} Recieved</div></div>
                                            {packageHistory.RecievedLocales==0?
                                            <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{width:("100%")}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={(packageHistory.TotalLocales)}><div style={{color:"black", fontWeight:"bold"}}>{packageHistory.TotalLocales} in progress</div></div>
                                            :
                                            <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{width:(packageHistory.TotalLocales-packageHistory.RecievedLocales==0?0:100*(packageHistory.RecievedLocales/packageHistory.TotalLocales)*100)+"%"}} aria-valuenow={(packageHistory.TotalLocales-packageHistory.RecievedLocales==0?0:(packageHistory.TotalLocales/packageHistory.RecievedLocales))} aria-valuemin={0} aria-valuemax={(packageHistory.TotalLocales)}><div style={{color:"black", fontWeight:"bold"}}>{packageHistory.TotalLocales-packageHistory.RecievedLocales} in progress</div></div>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            <hr/>
                            <div className="row">
                                <div className="col">
                                    Import Testing
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p>Import Test Graphic</p>
                                    <p>Import Test Percentage Text</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p>SQL Test</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p>DNT Test</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p>Static Translations Test</p>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col">
                                    <div>Download</div>
                                    <br/>
                                    <div className="row justify-content-center">
                                        <div className="col-5">
                                            <button role="button" onClick={downloadImportFile} className="btn btn-info">Download Import SQL</button>
                                        </div>
                                        <div className="col-5">
                                            <button type="button" className="btn btn-info">Download Testing SQL</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default Package;