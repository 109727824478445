import React, { useState } from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePopper } from "react-popper";

interface Props {
  title: string;
  refElement: any;
  popElement: any;
  arrowElement?: any;
  popoverContent: string;
}

export const Popover = (props: Props): any => {
  const [referenceElement, setReferenceElement] = useState(props.refElement);
  const [popperElement, setPopperElement] = useState(props.popElement);
  const [arrowElement, setArrowElement] = useState(props.arrowElement);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    }
  );

  const toggle = (): any => {
    let classAttr: any = popperElement.attributes["class"].nodeValue;
    if (classAttr === "popover-hide") {
      popperElement.setAttribute("class", "popover-show");
      if (update) {
        update();
      }
    } else {
      popperElement.setAttribute("class", "popover-hide");
    }
  };

  return (
    <>
      <FontAwesomeIcon
        type="button"
        ref={setReferenceElement}
        onClick={toggle}
        icon={faQuestionCircle}
        size="lg"
        className="popover-icon"
      ></FontAwesomeIcon>

      <div
        title={props.title}
        ref={setPopperElement}
        role="tooltip"
        className="popover-hide"
        style={styles.popper}
        {...attributes.popper}
      >
        {props.popoverContent}
        {<div title={props.title} ref={setArrowElement} style={styles.arrow} />}
      </div>
    </>
  );
};
