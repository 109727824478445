import React from "react";
import DataGrid from "../components/data-grid/data-grid";
import { Redirect } from "../utilities/utilities";

interface ISupportedAppsProps {}
interface ISupportedAppsState {
  renderCount: number;
  redirect: boolean;
}

class SupportedApps extends React.Component<
  ISupportedAppsProps,
  ISupportedAppsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      renderCount: 0,
      redirect: false,
    };
    this.buildColumnList = this.buildColumnList.bind(this);
  }

  buildColumnList(): any {
    const cols = [
      {
        DataPropertyName: "SupportedAppId",
        IsIdColumn: true,
      },
      {
        DataPropertyName: "AppName",
        ColumnAliasName: "App Name",
        InputType: "text",
        ShowColumn: true,
      },
      {
        DataPropertyName: "ConnectionString",
        ColumnAliasName: "Connection String",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
        SanitizeInput: false,
      },
      {
        DataPropertyName: "RepoUri",
        ColumnAliasName: "Repo Uri",
        InputType: "text",
        ShowColumn: true,
        CanEdit: true,
        SanitizeInput: false,
      },
      {
        DataPropertyName: "APIEndPoint",
        ColumnAliasName: "API EndPoint",
        InputType: "text",
        ShowColumn: true,
        CanEdit: true,
        SanitizeInput: false,
      },
      {
        ColumnAliasName: "Actions",
        ShowColumn: true,
        IsActionsColumn: true,
      },
    ];
    return cols;
  }

  render() {
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Supported Apps</h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <DataGrid
                ColumnArray={this.buildColumnList()}
                SelectAPI="SupportedAppsService/getsupportedappsonscroll"
                UpdateAPI="SupportedAppsService"
                DeleteAPI="SupportedAppsService"
                GridName="editSupportedApp"
                MaxNumberOfRowsPerPage={50}
                PrimaryKeyName="SupportedAppId"
                SortByColumn="AppName"
                LoadOnScroll={true}
              />
            </div>
          </div>
        </div>
        {this.state.redirect ? (
          <Redirect path="/login" isLogout={true} />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default SupportedApps;
