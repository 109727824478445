import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MakeRequest } from "./data-util";
import { UserProps } from "./../components/icomponents";
import { RemoveTokenFromLocalStorage } from "./../components/account/authentication-context";

interface Props {
  path: string;
  isLogout?: boolean;
}
export function Redirect(props: Props): any {
  const navigate = useNavigate();

  if (
    props.isLogout !== null &&
    props.isLogout !== undefined &&
    props.isLogout
  ) {
    RemoveTokenFromLocalStorage();
  }

  useEffect(() => {
    navigate(props.path);
  });
  return <></>;
}

interface FormProps {
  element: HTMLFormElement;
}

export function getAllFormElements(props: FormProps): any {
  const result = Array.from(props.element.elements).filter((tag) =>
    ["select", "textarea", "input"].includes(tag.tagName.toLowerCase())
  );
  return result;
}

export function showValidationErrors(errors: any): void {
  //show notification
  errors.forEach((element: any) => {
    if (
      document.getElementById(element.fieldName) !== null &&
      document.getElementById(element.fieldName) !== undefined
    ) {
      let inputElement: HTMLInputElement = document.getElementById(
        element.fieldName
      ) as HTMLInputElement;
      if (!inputElement.classList.contains("form-control-required-field")) {
        inputElement.classList.add("form-control-required-field");
      }
    }
  });
}

export function resetValidationErrors(element: HTMLInputElement): void {
  //reset styles
  if (element.classList.contains("form-control-required-field")) {
    element.classList.remove("form-control-required-field");
  }
}

export function getUserCulture(callback: any): void {
  let userCulture: string = "en";
  function successCallback(result: any): void {
    if (result && result !== undefined) {
      userCulture = result.UserCulture;
      callback(userCulture);
    }
  }
  function errorCallback(error: Error): void {
    if (error) {
      callback(userCulture);
    }
  }
  getUserCultureAsync({
    url: "CommonService/GetUserCulture",
    userId: "",
    successCallback,
    errorCallback,
  });
}

export async function getUserCultureAsync(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: {},
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}
