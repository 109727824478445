import React from "react";
import DataGrid from "../components/data-grid/data-grid";
import { Redirect } from "../utilities/utilities";

interface IDntListProps {}
interface IDntListState {
  renderCount: number;
  redirect: boolean;
}

class DntList extends React.Component<IDntListProps, IDntListState> {
  constructor(props: any) {
    super(props);
    this.state = {
      renderCount: 0,
      redirect: false,
    };
    this.buildColumnList = this.buildColumnList.bind(this);
  }
  buildColumnList(): any {
    const cols = [
      {
        DataPropertyName: "DoNotTranslateId",
        IsIdColumn: true,
      },
      {
        DataPropertyName: "TableName",
        ColumnAliasName: "Table Name",
        InputType: "text",
        ShowColumn: true,
        CanEdit: true,
        IsRequired: true,
      },
      {
        ColumnAliasName: "Actions",
        ShowColumn: true,
        IsActionsColumn: true,
      },
    ];
    return cols;
  }
  render() {
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Do Not Translate Tables</h3>
            <div className="row">
              <div className="col-md-12">
                <DataGrid
                  ColumnArray={this.buildColumnList()}
                  SelectAPI="Rules/getdntlist"
                  UpdateAPI="Rules/updatedntlist"
                  DeleteAPI="Rules/deletednttable"
                  GridName="dntList"
                  MaxNumberOfRowsPerPage={20}
                  PrimaryKeyName="DoNotTranslateId"
                  SortByColumn="TableName"
                  LoadOnScroll={false}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect ? (
          <Redirect path="/login" isLogout={true} />
        ) : (
          <></>
        )}
      </>
    );
  }
}
export default DntList;
