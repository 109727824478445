import React from "react";
import { useState, useEffect } from "react";

interface PropsExtended {
  id: string;
  label?: any;
  checked?: boolean;
  onSelecting: any;
  datarequired: boolean;
  datarowid?: string;
  labelClass?: string;
  dataprimarykeyname?: string;
  datavaluepropertyname?: string;
  datavalidationname?: string;
}
const Checkbox = (propsExt: PropsExtended, { ...props }): any => {
  const defaultChecked = propsExt.checked ? propsExt.checked : false;
  const [isChecked, setIsChecked] = useState(defaultChecked);
  useEffect(() => {
    setIsChecked(propsExt.checked || false);
  }, []);
  const handleSelected = (e: any) => {
    setIsChecked((prev) => !prev);
    propsExt.onSelecting(e);
  };

  return (
    <div className="checkbox-wrapper">
      {propsExt.label !== null && propsExt.label !== undefined && (
        <label htmlFor={propsExt.id} className={propsExt.labelClass}>
          {propsExt.label}
        </label>
      )}
      <input
        type="checkbox"
        className={isChecked ? "checked" : ""}
        onChange={handleSelected}
        checked={isChecked}
        data-required={propsExt.datarequired}
        data-rowid={propsExt.datarowid}
        data-primarykeyname={propsExt.dataprimarykeyname}
        data-valuepropertyname={propsExt.datavaluepropertyname}
        data-validationname={propsExt.datavalidationname}
        {...props}
      />
    </div>
  );
};

export default Checkbox;