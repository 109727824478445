import React from "react";
import { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import navValues from "../components/menu/nav-values";
import MenuPicker from "../components/menu/menu-picker";
import HomeRoute from "./home";
import {
  SetTokenStatus,
  GetTokenFromLocalStorage,
} from "../components/account/authentication-context";

//if page is refreshed we need to check the token status
//and set the initial value of the menu
SetTokenStatus();
var currentState: string =
  GetTokenFromLocalStorage() !== null
    ? navValues.loggedIn
    : navValues.loggedOut;

const navigationContext = React.createContext(currentState) as any;

const Root = (): any => {
  const navigate = useCallback(
    (useNav: any) => setNav({ current: useNav, navigate }),
    []
  );
  const [nav, setNav] = useState({ current: currentState, navigate }) as any;

  const location = useLocation();

  return (
    <>
      <navigationContext.Provider value={nav}>
        <MenuPicker currentNav={nav.current} />
      </navigationContext.Provider>
      {location.pathname === "/" && <HomeRoute />}
    </>
  );
};

export { navigationContext };
export default Root;
