import React from "react";

interface IOutputItemProps {
  value: string;
  className: string;
  level: string;
}

interface OutputItemState {}

class OutputItem extends React.Component<IOutputItemProps, OutputItemState> {
  constructor(props: IOutputItemProps) {
    super(props);
  }
  render() {
    return (
      <li className={this.props.className}>
        {this.props.level}:&nbsp;{this.props.value}
      </li>
    );
  }
}

export default OutputItem;
