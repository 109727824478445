import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import SqlFiles from "./routes/sql-files";
import ExportFiles from "./routes/export-files";
import SupportedCultures from "./routes/supported-cultures";
import SupportedApps from "./routes/supported-apps";
import AddSupportedCulture from "./routes/add-supported-culture";
import AddSupportedApp from "./routes/add-supported-app";
import DefaultExport from "./routes/default-export";
import CustomExport from "./routes/custom-export";
import AddDntTable from "./routes/add-dnt-table";
import DntList from "./routes/dnt-list";
import Settings from "./routes/settings";
import PackageHistory from "./routes/package-history";
import Import from "./routes/import";
import Package from "./components/package";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "sqlfiles",
        element: <SqlFiles />,
      },
      {
        path: "exportfiles",
        element: <ExportFiles />,
      },
      {
        path: "supportedcultures",
        element: <SupportedCultures />,
      },
      {
        path: "supportedapps",
        element: <SupportedApps />,
      },
      {
        path: "addsupportedculture",
        element: <AddSupportedCulture />,
      },
      {
        path: "addsupportedapp",
        element: <AddSupportedApp />,
      },
      {
        path: "addsupportedapp",
        element: <AddSupportedApp />,
      },
      {
        path: "defaultexport",
        element: <DefaultExport />,
      },
      {
        path: "customexport",
        element: <CustomExport />,
      },
      {
        path: "adddnttable",
        element: <AddDntTable />,
      },
      {
        path: "dntlist",
        element: <DntList />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "packagehistory",
        element: <PackageHistory />,
      },
      {
        path: "package/:packageId",
        element: <Package />,
      },
      {
        path: "import",
        element: <Import />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <RouterProvider router={router} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
