import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

interface IPackageProps {
  PackageId: string;
  LastModifiedTimeStamp: any;
  ImportComplete: boolean;
  onCommit: any;
  onCopy: any;
}

interface PackageState {}

class Package extends React.Component<IPackageProps, PackageState> {
  constructor(props: IPackageProps) {
    super(props);
  }
  render() {
    return (
      <div className="card card-profile">
        <div className="card-bod">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h4 className="card-title">Package</h4>
                <div className="row">
                  <div className="col-sm-12">
                    <h6 className="card-category">
                      {this.props.PackageId}

                      <button
                        title="Copy"
                        className="invisibleButton"
                        onClick={this.props.onCopy}
                        value={this.props.PackageId}
                      >
                        <FontAwesomeIcon icon={faCopy} size="lg" />
                      </button>
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 card-description">
                    <h6 className="card-category">
                      {new Date(
                        this.props.LastModifiedTimeStamp
                      ).toDateString()}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 card-description">
                    {this.props.ImportComplete ? (
                      <>
                        <span className="check">
                          <h3>Has Import Files: &nbsp;</h3>
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            size="lg"
                            className="check"
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="uncheck">
                          <strong>Has Import Files: &nbsp;</strong>
                        </span>
                        <span className="icon icon-default">
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            size="lg"
                            className="uncheck"
                          />
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card-footer text-center">
                      <button
                        key={"btn_" + this.props.PackageId}
                        className={
                          this.props.ImportComplete === true
                            ? "btn btn-success btn-round btn-left"
                            : "btn btn-default btn-round"
                        }
                        onClick={this.props.onCommit}
                        value={this.props.PackageId}
                        disabled={
                          this.props.ImportComplete === true ? false : true
                        }
                      >
                        Commit Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Package;
