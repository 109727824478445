import React from "react";
import DataGrid from "../components/data-grid/data-grid";
import { Redirect } from "../utilities/utilities";

interface ISupportedCulturesProps {}
interface ISupportedCulturesState {
  renderCount: number;
  redirect: boolean;
}

class SupportedCultures extends React.Component<
  ISupportedCulturesProps,
  ISupportedCulturesState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      renderCount: 0,
      redirect: false,
    };
    this.buildColumnList = this.buildColumnList.bind(this);
  }

  buildColumnList(): any {
    const cols = [
      {
        DataPropertyName: "SupportedCultureId",
        IsIdColumn: true,
      },
      {
        DataPropertyName: "CultureId",
        ColumnAliasName: "Culture Id",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "CultureCode",
        ColumnAliasName: "Culture Code",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "SmartlingCultureCode",
        ColumnAliasName: "Smartling Culture Code",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "CultureName",
        ColumnAliasName: "Culture Name",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "IsFullySupported",
        ColumnAliasName: "Is Fully Supported",
        InputType: "checkbox",
        ShowColumn: true,
        CanEdit: true,
      },
      {
        ColumnAliasName: "Actions",
        ShowColumn: true,
        IsActionsColumn: true,
      },
    ];
    return cols;
  }

  render() {
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Supported Cultures</h3>
            <div className="row">
              <div className="col-md-12">
                <DataGrid
                  ColumnArray={this.buildColumnList()}
                  SelectAPI="SupportedCultureService/getsupportedcultures"
                  UpdateAPI="SupportedCultureService"
                  DeleteAPI="SupportedCultureService"
                  GridName="editSupportedCulture"
                  MaxNumberOfRowsPerPage={20}
                  PrimaryKeyName="SupportedCultureId"
                  SortByColumn="CultureCode"
                  LoadOnScroll={false}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect ? (
          <Redirect path="/login" isLogout={true} />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default SupportedCultures;
