import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Checkbox from "../checkbox/Checkbox";
import GridSelect from "./data-grid-row-select";
import { Popover } from "../pop-over/pop-over";

interface DataGridRowProps {
  ColumnArray?: [];
  RowData: [];
  UpdateAPI?: string;
  DeleteAPI?: string;
  OnChangedRow?: any;
  OnEdit?: any;
  OnDelete?: any;
  OnAPILinkClick?: any;
}

interface DataGridRowState {
  rowId: string;
  editMode: boolean;
  primaryKeyName: string;
  renderCount: number;
}

class DataGridRow extends React.Component<DataGridRowProps, DataGridRowState> {
  constructor(props: DataGridRowProps) {
    super(props);
    this.state = {
      rowId: "",
      editMode: false,
      primaryKeyName: "",
      renderCount: 0,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleting = this.handleDeleting.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.buildCells = this.buildCells.bind(this);
  }
  CustomStyle = {
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? "black" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  handleEditClick(e: any): void {
    this.setState({ editMode: true }, () => {
      this.props.OnEdit(true);
    });
  }
  handleDeleting = (e: any): void => {
    let row: any = e.currentTarget;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.handleDeleteClick(row);
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };
  handleDeleteClick(e: any) {
    this.props.OnDelete(e);
  }
  handleOnChange(e: any) {
    this.props.OnChangedRow(e);
  }
  handleLinkClick(e: any) {
    if (e.target.attributes !== null && e.target.attributes.length > 0) {
      let apiUri = e.target.attributes["data-apivalue"].value;
      let fileName = e.target.attributes["data-filename"].value;
      if (
        apiUri !== null &&
        apiUri !== undefined &&
        fileName !== null &&
        fileName !== undefined
      ) {
        this.props.OnAPILinkClick(apiUri, fileName);
      }
    }
  }
  buildCells(): any {
    const cells = [];
    //loop through column array and populate cells array with jsx
    if (this.props.ColumnArray) {
      for (let i = 0; i < this.props.ColumnArray.length; i++) {
        let column: any = this.props.ColumnArray[i];
        if (column.ShowColumn) {
          let rowData = this.props.RowData;
          let cellValue: any = rowData[column.DataPropertyName];
          if (cellValue !== null && cellValue !== undefined) {
            if (
              !this.state.editMode ||
              column.CanEdit === null ||
              column.CanEdit === undefined ||
              !column.CanEdit
            ) {
              if (
                column.IsLink === null ||
                column.IsLink === undefined ||
                !column.IsLink
              ) {
                if (column.IsInfoColumn) {
                  cells.push(
                    <td key={"cell_popover_" + i}>
                      <Popover
                        title={"popover_" + i}
                        refElement={"refElement_" + i}
                        popElement={"popElement_" + i}
                        popoverContent={cellValue.toString()}
                      />
                    </td>
                  );
                } else {
                  if (
                    column.DataType !== null &&
                    column.DataType !== undefined
                  ) {
                    switch (column.DataType) {
                      case "datetime":
                        const date = new Date(cellValue.toString());
                        cells.push(<td key={i}>{date.toLocaleString()}</td>);
                        break;
                      default:
                        cells.push(<td key={i}>{cellValue.toString()}</td>);
                    }
                  } else {
                    cells.push(<td key={i}>{cellValue.toString()}</td>);
                  }
                }
              } else {
                //build the API and querystring values for the link
                let queryString: string = column.APIPath;
                if (
                  column.QueryStringParams &&
                  column.QueryStringParams !== undefined &&
                  column.QueryStringParams.length > 0
                ) {
                  //find the column and column prop value that matches the param
                  column.QueryStringParams.forEach((param: any) => {
                    this.props.ColumnArray?.forEach((item: any) => {
                      Object.keys(item).forEach((key) => {
                        if (param === item[key]) {
                          let value = rowData[item[key]];
                          queryString = queryString + "/" + value;
                        }
                      });
                    });
                  });
                }
                cells.push(
                  <td key={i}>
                    <a
                      href="#"
                      onClick={this.handleLinkClick}
                      data-apivalue={queryString}
                      data-filename={cellValue.toString()}
                    >
                      {cellValue.toString()}
                    </a>
                  </td>
                );
              }
            } else if (column.CanEdit) {
              if (
                column.InputType !== "checkbox" &&
                column.InputType !== "select"
              ) {
                cells.push(
                  <td key={i}>
                    <input
                      key={column.DataPropertyName + "_" + i}
                      id={column.DataPropertyName + "_" + this.state.rowId}
                      type={column.InputType}
                      value={cellValue.toString()}
                      onChange={this.handleOnChange}
                      data-rowid={this.state.rowId}
                      className="form-control border-input"
                      data-required={column.IsRequired}
                      data-primarykeyname={this.state.primaryKeyName}
                      data-textvaluepropertyname={column.DataPropertyName}
                      data-validationname={column.ColumnAliasName}
                      data-sanitizeinput={column.SanitizeInput}
                    />
                  </td>
                );
              } else if (column.InputType === "checkbox") {
                cells.push(
                  <td key={i}>
                    <Checkbox
                      key={column.DataPropertyName + "_" + i}
                      id={column.DataPropertyName + "_" + this.state.rowId}
                      checked={cellValue}
                      onSelecting={this.handleOnChange}
                      datarequired={false}
                      datarowid={this.state.rowId}
                      dataprimarykeyname={this.state.primaryKeyName}
                      datavaluepropertyname={column.DataPropertyName}
                      datavalidationname={column.ColumnAliasName}
                    />
                  </td>
                );
              } else if (column.InputType === "select") {
                let selectedValue =
                  this.props.RowData[column.DataPropertyValue];
                cells.push(
                  <td key={i}>
                    <GridSelect
                      key={column.DataPropertyName + "_" + i}
                      selectName={
                        column.DataPropertyName + "_" + this.state.rowId
                      }
                      id={column.DataPropertyName + "_" + this.state.rowId}
                      options={column.Options}
                      onChange={this.handleOnChange}
                      value={selectedValue}
                      datarowid={this.state.rowId}
                      dataRequired={column.IsRequired}
                      dataprimarykeyname={this.state.primaryKeyName}
                      datavaluepropertyname={column.DataPropertyName}
                      datavaluepropertyvalue={column.DataPropertyValue}
                      datavalidationname={column.ColumnAliasName}
                      datasanitizeinput={column.SanitizeInput}
                    />
                  </td>
                );
              }
            }
          }
        }
      }
      return cells;
    }
  }
  componentDidMount(): void {
    //get the id column if it exists
    if (this.props.ColumnArray) {
      let idColumn: any | null = this.props.ColumnArray.find(
        (c) => c["IsIdColumn"] !== null && c["IsIdColumn"]
      );
      this.setState({
        rowId: this.props.RowData[idColumn.DataPropertyName],
        primaryKeyName: idColumn.DataPropertyName,
      });
    }
  }
  render() {
    return (
      <>
        <tr>
          {this.buildCells()}
          {this.props.UpdateAPI && this.props.DeleteAPI ? (
            <td key={"edit_delete_" + this.state.rowId}>
              <button
                id={"edit_icon_" + this.state.rowId}
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Edit"
                className="btn btn-info btn-sm btn-round"
                onClick={this.handleEditClick}
                data-primarykeyname={this.state.primaryKeyName}
                data-rowid={this.state.rowId}
                data-command={"edit"}
                data-tooltip-content="Edit"
              >
                Edit
              </button>
              <button
                id={"delete_icon_" + this.state.rowId}
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Remove"
                className="btn btn-danger btn-sm btn-round"
                onClick={this.handleDeleting}
                data-primarykeyname={this.state.primaryKeyName}
                data-rowid={this.state.rowId}
                data-command={"delete"}
                data-tooltip-content="Delete"
              >
                Delete
              </button>
            </td>
          ) : this.props.UpdateAPI ? (
            <td key={"edit_" + this.state.rowId}>
              <button
                id={"edit_icon_" + this.state.rowId}
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Edit"
                className="btn btn-info btn-sm btn-round"
                onClick={this.handleEditClick}
                data-primarykeyname={this.state.primaryKeyName}
                data-rowid={this.state.rowId}
                data-command={"edit"}
                data-tooltip-content="Edit"
              >
                Edit
              </button>
            </td>
          ) : this.props.DeleteAPI ? (
            <td key={"delete_ " + this.state.rowId}>
              <button
                id={"delete_icon_" + this.state.rowId}
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Remove"
                className="btn btn-danger btn-sm btn-round"
                onClick={this.handleDeleting}
                data-primarykeyname={this.state.primaryKeyName}
                data-rowid={this.state.rowId}
                data-command={"delete"}
                data-tooltip-content="Delete"
              >
                Delete
              </button>
            </td>
          ) : null}
        </tr>
      </>
    );
  }
}

export default DataGridRow;
