import React from "react";
import { AxiosError } from "axios";
import { MakeRequest } from "../utilities/data-util";
import { Validator } from "../validation/validator";
import ValidationSummary from "../validation/validation-summary";
import {
  getAllFormElements,
  showValidationErrors,
} from "../utilities/utilities";
import { setToaster } from "../components/toaster/Toaster";
import { ToastContainer } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import SmallSpinner from "../components/SmallSpinner";

interface IAddSupportedAppProps {}

interface IAddSupportedAppState {
  appName: string;
  connectionString: string;
  repoUri: string;
  apiEndPoint: string;
  formErrors: any;
  doingWork: boolean;
}

class AddSupportedApp extends React.Component<
  IAddSupportedAppProps,
  IAddSupportedAppState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      appName: "",
      connectionString: "",
      repoUri: "",
      apiEndPoint: "",
      formErrors: [],
      doingWork: false,
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  postData(data: any): void {
    const successCallback = (result: any): void => {
      if (result === "") {
        setToaster({
          state: { success: true },
          message: "Success: App Added",
        });
        this.setState({
          appName: "",
          connectionString: "",
          repoUri: "",
          apiEndPoint: "",
          doingWork: false,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { success: false },
            message:
              "Failed: Could not add App: Response status " +
              error.response.status,
          });
        } else {
          setToaster({
            state: { success: false },
            message: "Failed: Could not add App: Unknown server error",
          });
        }
        this.setState({
          doingWork: false,
        });
      }
    };
    MakeRequest({
      url: "SupportedAppsService",
      method: "post",
      data: { postData: data },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  handleSubmit = (e: any) => {
    if (e !== null && e !== undefined) {
      e.preventDefault();
      const appName = this.state.appName.trim();
      const repoUri = this.state.repoUri.trim();
      const apiEndPoint = this.state.apiEndPoint.trim();
      const connString = this.state.connectionString.trim();

      let form = document.getElementById("addSupportedAppForm");
      let allFormElements = getAllFormElements({ element: form ? form : e });
      let errors = Validator({ fields: allFormElements });

      if (errors.length === 0) {
        this.postData({
          AppName: appName,
          ConnectionString: connString,
          RepoUri: repoUri,
          APIEndPoint: apiEndPoint,
        });
        this.setState({ formErrors: [], doingWork: true });
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  };
  handleTextChange(e: any) {
    if (e !== null && e !== undefined) {
      switch (e.target.id) {
        case "appNameInput":
          this.setState({ appName: e.target.value });
          break;
        case "repoUriInput":
          this.setState({ repoUri: e.target.value });
          break;
        case "apiEndPointInput":
          this.setState({ apiEndPoint: e.target.value });
          break;
        case "appConnStringInput":
          this.setState({ connectionString: e.target.value });
          break;
      }

      //is this a required field
      let errors = Validator({ fields: e.target });
      if (errors.length === 0) {
        this.setState({ formErrors: [] });
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  }

  render() {
    return (
      <div className="section-light-table">
        <div className="container">
          <h3 className="title">Add Supported App</h3>
          <div className="row">
            <div className="col-md-12 spinnerCell">
              <SmallSpinner
                isLoading={this.state.doingWork}
                actionText="Please Wait"
              />
            </div>
            <ValidationSummary Errors={this.state.formErrors} />

            <form
              id="addSupportedAppForm"
              onSubmit={this.handleSubmit}
              className=" input-form"
            >
              <div className="mb-3">
                <label
                  htmlFor="appNameInput"
                  id="appNameLabel"
                  className="form-label"
                >
                  App Name:
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="appNameInput"
                  value={this.state.appName}
                  onChange={this.handleTextChange}
                  data-required={true}
                  data-id="appNameInput"
                  data-validationname="App name"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="repoUriInput"
                  id="repoUriLabel"
                  className="form-label"
                >
                  Repository Uri:
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="repoUriInput"
                  value={this.state.repoUri}
                  onChange={this.handleTextChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="apiEndPointInput"
                  id="apiEndPointLabel"
                  className="form-label"
                >
                  API Endpoint:
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="apiEndPointInput"
                  value={this.state.apiEndPoint}
                  onChange={this.handleTextChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="appConnStringInput"
                  id="connStringLabel"
                  className="form-label"
                >
                  Connection String:
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="appConnStringInput"
                  value={this.state.connectionString}
                  onChange={this.handleTextChange}
                  data-required={true}
                  data-id="appConnStringInput"
                  data-validationname="Connection string"
                />
              </div>
              <div className="col">
                <button
                  type={"submit"}
                  id="btnAddApp"
                  className="btn btn-success btn-round"
                >
                  Add App
                </button>
              </div>
            </form>
          </div>
          <ToastContainer autoClose={2000} position="bottom-right" />
        </div>
      </div>
    );
  }
}

export default AddSupportedApp;
