import React from "react";
import { setToaster } from "../components/toaster/Toaster";
import { ToastContainer } from "react-toastify";
import { MakeRequest } from "../utilities/data-util";
import { AxiosError } from "axios";
import OutputList from "../components/output-box/output-list";
import SmallSpinner from "../components/SmallSpinner";
import Package from "../components/importpackage";
import Checkbox from "../components/checkbox/Checkbox";
import { Popover } from "../components/pop-over/pop-over";

interface IDefaultExportProps {}

interface IDefaultExportState {
  data: any;
  runExport: boolean;
  doingWork: boolean;
  renderCount: number;
  noData: boolean;
  processId: string;
  isCancelled: boolean;
  uncommittedExports: boolean;
  exportChanges: any;
  testMode: boolean;
  exportType: string;
}

class DefaultExport extends React.Component<
  IDefaultExportProps,
  IDefaultExportState
> {
  constructor(props: IDefaultExportProps) {
    super(props);
    this.state = {
      data: [],
      runExport: false,
      doingWork: false,
      renderCount: 0,
      noData: false,
      processId: "",
      isCancelled: false,
      uncommittedExports: false,
      exportChanges: [],
      testMode: false,
      exportType: "Default",
    };
    this.handleCopy = this.handleCopy.bind(this);
    this.handleStartExport = this.handleStartExport.bind(this);
    this.handleCancelExport = this.handleCancelExport.bind(this);
    this.handleExportStatus = this.handleExportStatus.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
    this.handleTestModeCheckboxOnChange =
      this.handleTestModeCheckboxOnChange.bind(this);
  }
  async loadData(formData: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({
          data: result,
          processId: result.ProcessId,
          noData: false,
          runExport: true,
          renderCount: this.state.renderCount + 1,
          doingWork: true,
        });
      } else {
        this.setState({ noData: true, doingWork: false, runExport: false });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState(
          { doingWork: false, noData: true, runExport: false },
          () => {
            setToaster({
              state: { error: true },
              message: error.response
                ? error.message
                : "Unknown Error: Check DTM Service",
            });
            this.handleCancelExport();
          }
        );
      }
    };
    MakeRequest({
      url: "ExportFileService/DefaultExport",
      method: "post",
      data: { postData: formData },
      successCallback: successCallback,
      errorCallback: errorCallback,
      isFormData: true,
    });
  }
  async getUnCommittedChanges(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result && result.Data) {
        this.setState({
          exportChanges: result.Data,
          uncommittedExports: result.Data.length > 0 ? true : false,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "ExportFileService/GetUncommitedPackageIds",
      method: "get",
      data: {},
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  commitPackage(data: any): void {
    const successCallback = (result: any): void => {
      if (result !== null && result.OperationComplete) {
        setToaster({
          state: { success: true },
          message: "Success: Package committed",
        });
        this.getUnCommittedChanges();
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { success: false },
            message:
              "Failed: Could not commit package: Response status " +
              error.response.status,
          });
        } else {
          setToaster({
            state: { success: false },
            message: "Failed: Could not commit package: Unknown server error",
          });
        }
        this.setState({
          doingWork: false,
        });
      }
    };
    MakeRequest({
      url: "ExportFileService/CommitTemporaryExportData",
      method: "post",
      data: { postData: data },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  handleCopy = async (e: any) => {
    try {
      await navigator.clipboard.writeText(e.target.value);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  handleCommit = async (e: any): Promise<any> => {
    if (e !== null && e !== undefined && e.target && e.target.value !== "") {
      this.commitPackage({ packageId: e.target.value });
    }
  };
  handleStartExport = async (e: any): Promise<any> => {
    e.preventDefault();
    if (!this.state.runExport) {
      this.setState({ isCancelled: false }, () => {
        let formData = new FormData();
        formData.append("exportType", JSON.stringify(this.state.exportType));
        formData.append("testMode", JSON.stringify(this.state.testMode));
        this.loadData(formData);
      });
    }
  };
  handleCancelExport = (): void => {
    this.setState(
      {
        doingWork: false,
        runExport: false,
        isCancelled: true,
        renderCount: this.state.renderCount + 1,
      },
      () => {
        setToaster({
          state: { success: true },
          message: "Export Cancelled",
        });
      }
    );
  };
  handleExportStatus = (isRunning: boolean): any => {
    if (!isRunning && this.state.doingWork) {
      this.setState({ doingWork: false, runExport: false });
      setToaster({
        state: { success: true },
        message: "Export Complete",
      });
    }
  };
  scrollOutput = () => {
    let outputBoxRef = document.getElementById("outputBox");
    if (outputBoxRef && outputBoxRef !== undefined) {
      outputBoxRef.scrollTop = outputBoxRef.scrollHeight + 10;
    }
  };
  handleTestModeCheckboxOnChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ testMode: e.target.checked });
    }
  };
  componentDidMount() {
    this.getUnCommittedChanges();
  }
  render() {
    return (
      <div id="defaultexport">
        <div className="section-light">
          <div className="container">
            <div className="row">
              <div className="col-md-8 ml-auto mr-auto text-left">
                <h4 className="title">Default Export</h4>

                {this.state.uncommittedExports && (
                  <div className="notifications">
                    <div className="alert alert-info">
                      <div className="container">
                        <p>
                          <strong>
                            There are un-committed export changes for the
                            following packages. Please ensure to commit these
                            packages once their associated import is complete.
                          </strong>
                        </p>
                        {this.state.exportChanges.length > 1 && (
                          <p>
                            <strong>
                              The order in which these packages are committed is
                              important. Commit order should be oldest to newest
                            </strong>
                          </p>
                        )}
                        {this.state.exportChanges.map((item: any) => (
                          <Package
                            key={"package_" + item.PackageId}
                            PackageId={item.PackageId}
                            LastModifiedTimeStamp={item.LastModifiedTimeStamp}
                            ImportComplete={item.ImportComplete}
                            onCommit={this.handleCommit}
                            onCopy={this.handleCopy}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <p>
                  The default export can be triggered manually by clicking the
                  "Start Export" button below.
                </p>
                <div className="col-lg-12">
                  <form
                    id="defaultExportForm"
                    onSubmit={this.handleStartExport}
                    className="row g-3"
                  >
                    <div className="col-12">
                      <label
                        htmlFor="testMode"
                        id="testMode"
                        className="form-label"
                      >
                        Test Mode
                      </label>
                      <Popover
                        title="testMode"
                        refElement="testMode"
                        popElement="testMode-text"
                        popoverContent="Local Development Environment only! This will bypass Smartling completely."
                      />
                      <Checkbox
                        key={"chkTestMode_" + this.state.renderCount}
                        id="testModeChk"
                        checked={this.state.testMode}
                        onSelecting={this.handleTestModeCheckboxOnChange}
                        datarequired={false}
                      />
                    </div>
                    <div className="col-md-6">
                      <button
                        type="submit"
                        className="btn btn-success btn-round btn-left"
                      >
                        Start Export
                      </button>
                    </div>

                    <div className="col-md-6">
                      <button
                        type="reset"
                        className="btn btn-default btn-round  btn-left"
                        onClick={this.handleCancelExport}
                      >
                        Cancel Export
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12 spinnerCell">
                <SmallSpinner
                  isLoading={this.state.doingWork}
                  actionText="Exporting, Please Wait"
                />
              </div>
              <div className="col-md-8 ml-auto mr-auto text-left">
                <div id="outputBox" className="outputBox scrollable">
                  <OutputList
                    key={"ItemList_" + this.state.renderCount}
                    count={this.state.renderCount}
                    onExport={this.handleExportStatus}
                    runExport={this.state.runExport}
                    currentProcessId={this.state.processId}
                    onScroll={this.scrollOutput}
                    isCancelled={this.state.isCancelled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={4000} position="bottom-right" />
      </div>
    );
  }
}

export default DefaultExport;
