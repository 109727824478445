import React from "react";
import { setToaster } from "../components/toaster/Toaster";
import { ToastContainer } from "react-toastify";
import { MakeRequest } from "../utilities/data-util";
import { AxiosError } from "axios";
import OutputList from "../components/output-box/output-list";
import ExportTemplate from "../components/export-template/export-template";
import SmallSpinner from "../components/SmallSpinner";
import ValidationSummary from "../validation/validation-summary";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Checkbox from "../components/checkbox/Checkbox";
import "react-datepicker/dist/react-datepicker.css";
import { Popover } from "../components/pop-over/pop-over";
import ExportStringsCollection from "../components/export-strings/export-strings-collection";
import { sanitizeInput } from "../validation/validator";

interface ICustomExportProps {}
interface ICustomExportState {
  data: any;
  runExport: boolean;
  doingWork: boolean;
  renderCount: number;
  noData: boolean;
  processId: string;
  formErrors: any;
  supportedApps: any;
  supportedCultures: any;
  selectedCultures: string[];
  tables: any;
  selectedTables: string[];
  startDate: Date;
  lookbackDays: number;
  isCancelled: boolean;
  useLookback: boolean;
  testMode: boolean;
  exportType: string;
  exportTableStrings: boolean;
  searchQueryValue: string;
  tableStringsCollection: any[];
  searchType: string;
}
class CustomExport extends React.Component<
  ICustomExportProps,
  ICustomExportState
> {
  constructor(props: ICustomExportProps) {
    super(props);
    this.state = {
      data: [],
      runExport: false,
      doingWork: false,
      renderCount: 0,
      noData: false,
      processId: "",
      formErrors: [],
      supportedApps: [],
      supportedCultures: [],
      selectedCultures: [],
      tables: [],
      selectedTables: [],
      startDate: new Date(),
      lookbackDays: 0,
      isCancelled: false,
      useLookback: false,
      testMode: false,
      exportType: "Custom",
      exportTableStrings: false,
      searchQueryValue: "",
      tableStringsCollection: [],
      searchType: "fuzzy",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormKeyDown = this.handleFormKeyDown.bind(this);
    this.handleCancelExport = this.handleCancelExport.bind(this);
    this.handleExportStatus = this.handleExportStatus.bind(this);
    this.handleOnCultureChange = this.handleOnCultureChange.bind(this);
    this.handleOnTableChange = this.handleOnTableChange.bind(this);
    this.handleOnDateChange = this.handleOnDateChange.bind(this);
    this.handleCheckboxOnChange = this.handleCheckboxOnChange.bind(this);
    this.handleTestModeCheckboxOnChange =
      this.handleTestModeCheckboxOnChange.bind(this);
    this.handleSpecificStringsOnChange =
      this.handleSpecificStringsOnChange.bind(this);
    this.handleTableStringsChange = this.handleTableStringsChange.bind(this);
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
    this.handleSearchTypeChange = this.handleSearchTypeChange.bind(this);
  }
  populateTemplateData = (data: any): void => {
    if (data) {
      this.setState({
        selectedCultures: data.selectedCultures,
        selectedTables: data.selectedTables,
        useLookback: data.useLookback,
        startDate: data.startDate === null ? new Date() : data.startDate,
        renderCount: this.state.renderCount + 1,
      });
    } else {
      this.setState({
        selectedCultures: [],
        selectedTables: [],
        useLookback: false,
        startDate: new Date(),
        renderCount: this.state.renderCount + 1,
      });
    }
  };

  handleCancelExport = (): void => {
    this.setState(
      {
        doingWork: false,
        runExport: false,
        isCancelled: true,
        renderCount: this.state.renderCount + 1,
      },
      () => {
        setToaster({
          state: { success: true },
          message: "Export Cancelled",
        });
      }
    );
  };
  handleExportStatus = (isRunning: boolean): any => {
    if (!isRunning && this.state.doingWork) {
      this.setState({ doingWork: false, runExport: false });
      setToaster({
        state: { success: true },
        message: "Export Complete",
      });
    }
  };
  async getSupportedApps(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState(
          {
            supportedApps: result,
          },
          () => {
            this.getTableList();
          }
        );
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "SupportedAppsService",
      method: "get",
      data: {},
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  async getSupportedCultures(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({
          supportedCultures: result,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "SupportedCultureService/getsupportedcultures",
      method: "get",
      data: {},
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  async getTableList(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({
          tables: result,
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "CommonService/gettablelist",
      method: "get",
      data: {
        querystringParams:
          "/" + parseInt(this.state.supportedApps[0].SupportedAppId),
      },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  async postData(formData: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({
          data: result,
          processId: result.ProcessId,
          noData: false,
          runExport: true,
          renderCount: this.state.renderCount + 1,
          doingWork: true,
        });
      } else {
        this.setState({ noData: true, doingWork: false, runExport: false });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState(
          { doingWork: false, noData: true, runExport: false },
          () => {
            setToaster({
              state: { error: true },
              message: error.response
                ? error.message
                : "Unknown Error: Check DTM Service",
            });
          }
        );
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "ExportFileService/CustomExport",
      method: "post",
      data: { postData: formData },
      successCallback: successCallback,
      errorCallback: errorCallback,
      isFormData: false,
    });
  }
  handleFormKeyDown = (e: any) => {
    // Prevent form submission when Enter key is pressed,
    // unless focused form element type is "submit" or "reset"
    const targetType = e.target.type;
    const enterKeyPressed = e.code === "Enter";
    if (enterKeyPressed && targetType !== "submit" && targetType !== "reset") {
      e.preventDefault();
    }
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (e !== null && e !== undefined) {
      //do we have any values
      if (
        this.state.selectedCultures.length > 0 ||
        this.state.selectedTables.length > 0 ||
        this.state.lookbackDays > 0 ||
        this.state.searchQueryValue !== "" ||
        this.state.useLookback
      ) {
        if (!this.state.runExport) {
          this.setState({ isCancelled: false }, () => {
            let selectedCultures: any[] = [];
            this.state.selectedCultures.forEach((culture: any) => {
              selectedCultures.push(culture.value);
            });

            let selectedTables: any[] = [];
            this.state.selectedTables.forEach((table: any) => {
              selectedTables.push(table.value);
            });

            let searchTerm: string = this.state.searchQueryValue.trim();

            if (
              searchTerm !== null &&
              searchTerm !== undefined &&
              searchTerm !== ""
            ) {
              if (this.state.searchType === "fuzzy") {
                searchTerm = "%" + searchTerm + "%";
              }
            }

            let data: any = {
              SupportedAppId: this.state.supportedApps[0].SupportedAppId,
              ExportLookbackInDays: this.state.lookbackDays,
              TestMode: this.state.testMode,
              CulturesArray: selectedCultures,
              SearchTerm: searchTerm,
              SearchType: this.state.searchType === "fuzzy" ? 2 : 1,
              TablesArray: selectedTables,
              TableStrings: this.state.tableStringsCollection,
            };

            this.postData(data);
          });
        }
      } else {
        setToaster({
          state: { error: true },
          message: "Error: Please select some values",
        });
      }
    }
  };
  handleOnDateChange = (e: any) => {
    if (e !== null && e !== undefined) {
      if (!isNaN(Date.parse(e))) {
        let today = new Date();
        let start = new Date(e);
        let diff = today.getTime() - start.getTime();
        let dayCount = Math.floor(diff / (1000 * 60 * 60 * 24));
        this.setState({ startDate: e, lookbackDays: dayCount });
      }
    }
  };
  scrollOutput = () => {
    let outputBoxRef = document.getElementById("outputBox");
    if (outputBoxRef && outputBoxRef !== undefined) {
      outputBoxRef.scrollTop = outputBoxRef.scrollHeight + 10;
    }
  };
  handleOnCultureChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ selectedCultures: e });
    }
  };
  handleOnTableChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ selectedTables: e });
    }
  };
  handleSpecificStringsOnChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ exportTableStrings: e.target.checked });
    }
  };
  handleTableStringsChange = (e: any) => {
    if (e !== null && e !== undefined && this.state.exportTableStrings) {
      let currentStrings: any[] = this.state.tableStringsCollection;

      if (currentStrings.length > 0) {
        let tableExists = currentStrings.find((table: any) => {
          if (e.tableName === table.tableName) {
            //update string values
            table.stringValues = e.stringValues;
          }
        });
        if (tableExists === undefined) {
          //add new table
          currentStrings.push(e);
        }
      } else {
        //add new table
        currentStrings.push(e);
      }

      this.setState({ tableStringsCollection: currentStrings });
    } else if (!this.state.exportTableStrings) {
      //clear out data
      this.setState({ tableStringsCollection: [] });
    }
  };
  handleSearchQueryChange = (e: any) => {
    if (e !== null && e !== undefined) {
      let value = sanitizeInput(e.target.value, e.target.type);
      this.setState({ searchQueryValue: value });
    }
  };
  handleSearchTypeChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ searchType: e.target.value });
    }
  };
  handleCheckboxOnChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ useLookback: e.target.checked });
    }
  };
  handleTestModeCheckboxOnChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ testMode: e.target.checked });
    }
  };
  componentDidMount = () => {
    this.getSupportedApps();
    this.getSupportedCultures();
  };
  render() {
    let cultureOptions: any = [];
    this.state.supportedCultures.forEach((option: any) => {
      cultureOptions.push({
        value: option.CultureCode.toString(),
        label:
          option.CultureCode.toString() + " " + option.CultureName.toString(),
      });
    });
    let tableOptions: any = [];
    this.state.tables.forEach((option: any) => {
      tableOptions.push({
        value: option.toString(),
        label: option.toString(),
      });
    });
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Custom Export</h3>
            <div className="row">
              <ValidationSummary Errors={this.state.formErrors} />
              <form
                id="addSupportedAppForm"
                onKeyDown={this.handleFormKeyDown}
                onSubmit={this.handleSubmit}
                className="row g-3"
              >
                <ExportTemplate
                  populateTemplateData={this.populateTemplateData}
                  selectedTables={this.state.selectedTables}
                  selectedCultures={this.state.selectedCultures}
                  selectedDate={this.state.startDate}
                  lookbackDays={this.state.lookbackDays}
                />

                <div className="col-12">
                  <h5>Parameters</h5>
                  <div className="alert alert-info">
                    <div className="container">
                      <span>
                        You must select either at least one culture or one
                        table, or a search term before the form becomes enabled.
                        Please select the tool-tip icons for more specific
                        information.
                      </span>
                    </div>
                  </div>
                  <div className="col-12 formElementContainer background-culture">
                    <label
                      htmlFor="drpCultures"
                      id="cultures"
                      className="form-label"
                    >
                      Cultures:
                    </label>
                    <Popover
                      title="Cultures"
                      refElement="cultures"
                      popElement="cultures-text"
                      popoverContent="You can select multiple cultures. To find a culture, select the dropdown and start typing it's code. Leaving the cultures dropdown empty means that all cultures will be exported as long as at least one table is selected in the tables dropdown."
                    />
                    <Select
                      id="cultures"
                      defaultValue={[cultureOptions[0], cultureOptions[1]]}
                      isMulti
                      name="colors"
                      options={cultureOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleOnCultureChange}
                      value={this.state.selectedCultures}
                    />
                  </div>
                  <div className="col-12 formElementContainer background-search">
                    <label htmlFor="query" id="lblquery" className="form-label">
                      String Search:
                    </label>
                    <Popover
                      title="query"
                      refElement="query"
                      popElement="query-text"
                      popoverContent="You can provide a short name value as a search term here. The export will search all base tables for every occurrence and include these in the export"
                    />
                    <input
                      id="lblquery"
                      type="text"
                      className="form-control"
                      onChange={this.handleSearchQueryChange}
                      value={this.state.searchQueryValue}
                    />
                  </div>
                  <div className="col-12 formElementContainer background-search">
                    <label
                      htmlFor="radios"
                      id="lblRadios"
                      className="form-label"
                    >
                      Search Type:
                    </label>
                    <Popover
                      title="searchType"
                      refElement="searchType"
                      popElement="searchType-text"
                      popoverContent="In most cases Fuzzy match is the most appropriate as this will look for the search term within the ShortName and LongName fields and return results even if the search term is part of a larger string. Exact match will only return results that match the search term exactly."
                    />
                    <div className="form-group-radio">
                      <label className="form-check-label" htmlFor="searchType1">
                        <input
                          key="radio1"
                          className="form-check-input"
                          type="radio"
                          name="searchType"
                          id="searchType1"
                          defaultValue="exact"
                          checked={this.state.searchType === "exact"}
                          onChange={this.handleSearchTypeChange}
                        />{" "}
                        Exact match
                      </label>
                    </div>
                    <div className="form-group-radio">
                      <label className="form-check-label" htmlFor="searchType2">
                        <input
                          key="radio2"
                          className="form-check-input"
                          type="radio"
                          name="searchType"
                          id="searchType2"
                          defaultValue="fuzzy"
                          checked={this.state.searchType === "fuzzy"}
                          onChange={this.handleSearchTypeChange}
                        />{" "}
                        Fuzzy Match
                      </label>
                    </div>
                  </div>
                  <div className="col-12 formElementContainer background-tables">
                    <label
                      htmlFor="tables"
                      id="lblDrpTables"
                      className="form-label"
                    >
                      Tables:
                    </label>
                    <Popover
                      title="tables"
                      refElement="tables"
                      popElement="tables-text"
                      popoverContent="You can select multiple tables. To find a table, select the dropdown and start typing it's name. Leaving the tables dropdown empty means that all tables will be exported as long as at least one culture is selected in the cultures dropdown."
                    />
                    <Select
                      id="tables"
                      defaultValue={[tableOptions[0], tableOptions[1]]}
                      isMulti
                      name="colors"
                      options={tableOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleOnTableChange}
                      value={this.state.selectedTables}
                    />
                  </div>
                  {/* 
                  <div className="col-12 formElementContainer background-tables">
                    <label
                      htmlFor="exportstrings"
                      id="exportstringsLabel"
                      className="form-label"
                    >
                      Export Specific Strings
                    </label>
                    <Popover
                      title="exportstrings"
                      refElement="tables"
                      popElement="tables-text"
                      popoverContent="Select if you want to be able to specify specific strings to export from selected tables. This search type performs an exact match, meaning that any search terms provided must match the target tables strings exactly."
                    />
                    <Checkbox
                      key={"chkexportstringsKey_" + this.state.renderCount}
                      id="exportstringsChk"
                      checked={this.state.exportTableStrings}
                      onSelecting={this.handleSpecificStringsOnChange}
                      datarequired={false}
                    />
                  </div>
                  <ExportStringsCollection
                    key={"ExportStringsCollection_" + this.state.renderCount}
                    data={this.state.selectedTables}
                    visible={this.state.exportTableStrings}
                    onCollectionChanged={this.handleTableStringsChange}
                  />
                  */}
                  <div className="col-12 formElementContainer background-tables">
                    <label
                      htmlFor="exportLookbackInDays"
                      id="lookbackLabel"
                      className="form-label"
                    >
                      Use Lookback
                    </label>
                    <Popover
                      title="tables"
                      refElement="tables"
                      popElement="tables-text"
                      popoverContent="Check to select a date to act as an export
                      start date. If unselected, all available table strings
                      will be exported"
                    />
                    <Checkbox
                      key={"chkKey_" + this.state.renderCount}
                      id="exportLookbackInDaysChk"
                      checked={this.state.useLookback}
                      onSelecting={this.handleCheckboxOnChange}
                      datarequired={false}
                    />
                  </div>
                  <div className="col-12 formElementContainer background-tables">
                    <DatePicker
                      id="exportLookbackInDays"
                      selected={this.state.startDate}
                      onChange={this.handleOnDateChange}
                      className="form-control"
                      disabled={this.state.useLookback ? false : true}
                    />
                    <hr />
                  </div>
                  <div className="col-12 formElementContainer">
                    <h5>Developer Options</h5>
                    <label
                      htmlFor="testMode"
                      id="testMode"
                      className="form-label"
                    >
                      Test Mode
                    </label>
                    <Popover
                      title="testMode"
                      refElement="testMode"
                      popElement="testMode-text"
                      popoverContent="Local Development Environment only! This will bypass Smartling completely."
                    />
                    <Checkbox
                      key={"chkTestMode_" + this.state.renderCount}
                      id="testModeChk"
                      checked={this.state.testMode}
                      onSelecting={this.handleTestModeCheckboxOnChange}
                      datarequired={false}
                    />
                    <hr />
                  </div>
                </div>
                <div className="col-md-6">
                  <button
                    type="submit"
                    className="btn btn-success btn-round btn-left"
                    disabled={
                      !(
                        this.state.selectedCultures.length > 0 ||
                        this.state.selectedTables.length > 0 ||
                        this.state.searchQueryValue !== "" ||
                        this.state.lookbackDays > 0 ||
                        this.state.useLookback
                      )
                    }
                  >
                    Start Export
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    type="reset"
                    className="btn btn-default btn-round btn-left"
                    onClick={this.handleCancelExport}
                    disabled={
                      !(
                        this.state.selectedCultures.length > 0 ||
                        this.state.selectedTables.length > 0 ||
                        this.state.searchQueryValue !== "" ||
                        this.state.lookbackDays > 0 ||
                        this.state.useLookback
                      )
                    }
                  >
                    Cancel Export
                  </button>
                </div>
              </form>
              <hr />
              <div className="col-lg-12">
                <div className="spinnerCell">
                  <SmallSpinner
                    isLoading={this.state.doingWork}
                    actionText="Exporting, Please Wait"
                  />
                  <div id="outputBox" className="outputBox scrollable">
                    <OutputList
                      key={"ItemList_" + this.state.renderCount}
                      count={this.state.renderCount}
                      onExport={this.handleExportStatus}
                      runExport={this.state.runExport}
                      currentProcessId={this.state.processId}
                      onScroll={this.scrollOutput}
                      isCancelled={this.state.isCancelled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={4000} position="bottom-right" />
      </>
    );
  }
}
export default CustomExport;
