import axios, { AxiosError } from "axios";
import configData from "../configData.json";

const client = axios.create({});

const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];

let baseUrl: string = configData.ENDPOINT_BASE;

//if we are running locally point to local DTM server
if (LOCAL_DOMAINS.includes(window.location.hostname)) {
  baseUrl = configData.DEVELOPMENT_BASE;
}

const config: any = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

const configPost: any = {
  headers: {
    "Content-Type": "application/json",
  },
};

const configFormPost: any = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const isEmpty = (obj: any): boolean => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

interface RequestProps {
  url: string | undefined;
  method: string;
  data: { querystringParams?: string; postData?: {} };
  successCallback?: Function;
  errorCallback?: Function;
  location?: string;
  isFormData?: boolean;
}

export async function Prerequisite(
  query: string,
  successCallback: any,
  errorCallback: any
) {
  if (query !== null && query !== undefined) {
    //ajax
    //get with params
    await client
      .get(baseUrl + query, config)
      .then((response) => {
        if (successCallback !== null && successCallback !== undefined) {
          successCallback(response.data);
        }
        return;
      })
      .catch((error: AxiosError) => {
        if (errorCallback !== null && errorCallback !== undefined) {
          errorCallback(error);
        }
        return;
      });
  }
}

export async function MakeRequest(props: RequestProps): Promise<any> {
  if (props !== null && props !== undefined) {
    switch (props.method) {
      case "get":
        if (
          props.data.querystringParams !== null &&
          props.data.querystringParams !== undefined
        ) {
          //get with params
          await client
            .get(baseUrl + props.url + props.data.querystringParams, config)
            .then((response) => {
              if (
                props.successCallback !== null &&
                props.successCallback !== undefined
              ) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (
                props.errorCallback !== null &&
                props.errorCallback !== undefined
              ) {
                props.errorCallback(error);
              }
              return;
            });
        } else {
          //get without params
          await client
            .get(baseUrl + props.url, config)
            .then((response) => {
              if (
                props.successCallback !== null &&
                props.successCallback !== undefined
              ) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (
                props.errorCallback !== null &&
                props.errorCallback !== undefined
              ) {
                props.errorCallback(error);
              }
              return;
            });
        }
        break;
      case "post":
        if (
          (props.data.postData !== null &&
            props.data.postData !== undefined &&
            !isEmpty(props.data.postData)) ||
          props.isFormData
        ) {
          //post
          let configToUse: any =
            props.isFormData !== null &&
            props.isFormData !== undefined &&
            props.isFormData
              ? configFormPost
              : configPost;
          await client
            .post(baseUrl + props.url, props.data.postData, configToUse)
            .then((response) => {
              if (
                props.successCallback !== null &&
                props.successCallback !== undefined
              ) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (
                props.errorCallback !== null &&
                props.errorCallback !== undefined
              ) {
                props.errorCallback(error);
              }
              return;
            });
        } else {
          //post
          await client
            .post(baseUrl + props.url)
            .then((response) => {
              if (
                props.successCallback !== null &&
                props.successCallback !== undefined
              ) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (
                props.errorCallback !== null &&
                props.errorCallback !== undefined
              ) {
                props.errorCallback(error);
              }
              return;
            });
        }
        break;
      case "put":
        if (props.data.postData !== null && props.data.postData !== undefined) {
          //put
          await client
            .put(baseUrl + props.url, props.data.postData, config)
            .then((response) => {
              if (
                props.successCallback !== null &&
                props.successCallback !== undefined
              ) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (
                props.errorCallback !== null &&
                props.errorCallback !== undefined
              ) {
                props.errorCallback(error);
              }
              return;
            });
        }
        break;
      case "delete":
        if (
          props.data.querystringParams !== null &&
          props.data.querystringParams !== undefined
        ) {
          //delete
          await client
            .delete(baseUrl + props.url + props.data.querystringParams, config)
            .then((response) => {
              if (
                props.successCallback !== null &&
                props.successCallback !== undefined
              ) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (
                props.errorCallback !== null &&
                props.errorCallback !== undefined
              ) {
                props.errorCallback(error);
              }
              return;
            });
        }
        break;
      default:
        //get without params
        await client
          .get(baseUrl + props.url, config)
          .then((response) => {
            if (
              props.successCallback !== null &&
              props.successCallback !== undefined
            ) {
              props.successCallback(response.data);
            } else {
              return response.data;
            }
          })
          .catch((error: AxiosError) => {
            if (
              props.errorCallback !== null &&
              props.errorCallback !== undefined
            ) {
              props.errorCallback(error);
            }
            return;
          });
    }
  }
}
