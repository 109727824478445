import React from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { setToaster } from "../toaster/Toaster";
import { MakeRequest } from "../../utilities/data-util";
import { AxiosError } from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";

interface IExportTemplateProps {
  exportTemplateName?: string;
  exportTemplateId?: number;
  selectedApp?: string;
  lookbackDate?: Date;
  lookbackDays?: number;
  useLookback?: boolean;
  populateTemplateData: Function;
  selectedCultures: any;
  selectedTables?: any;
  selectedDate: any;
}

interface IExportTemplateState {
  selectedTemplate: any;
  disableSaveBtn: boolean;
  exportTemplates: any[];
  oldTemplateName: string;
  newTemplateName: string;
  showNameModal: boolean;
  showDeleteModal: boolean;
  templateRenderCount: number;
}

export default class ExportTemplate extends React.Component<
  IExportTemplateProps,
  IExportTemplateState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedTemplate: null,
      disableSaveBtn: true,
      exportTemplates: [],
      oldTemplateName: "",
      newTemplateName: "",
      showNameModal: false,
      showDeleteModal: false,
      templateRenderCount: 0,
    };
  }

  componentDidMount(): void {
    this.getExportTemplates();
  }

  formatCultures = (template: any) => {
    let selected: any = [];
    template.selectedCultures.forEach((element: any) => {
      selected.push({
        value: element.value,
        label: element.label,
      });
    });
    return selected;
  };

  formatTables = (template: any) => {
    let selected: any = [];
    template.selectedTables.forEach((element: any) => {
      selected.push({
        value: element.value,
        label: element.label,
      });
    });
    return selected;
  };

  handleTemplateSelect = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState(
        { oldTemplateName: e.label, newTemplateName: e.label },
        () => {
          this.getSelectedExportTemplate(e.value);
        }
      );
    }
  };

  handleTemplateNameChange = (e: any) => {
    let str = this.state.oldTemplateName + e.target.value;
    this.setState({ newTemplateName: str });
  };

  handleTemplateNameInputChange = (e: any) => {
    this.setState({ newTemplateName: e.target.value });
  };

  handleSaveClick = (e: any) => {
    this.setState({ showNameModal: true }, () => {
      if (this.state.selectedTemplate) {
        this.setState({ newTemplateName: this.state.oldTemplateName });
      }
    });
  };

  handleModalSaveClick = () => {
    this.saveTemplate();
    this.setState({
      templateRenderCount: this.state.templateRenderCount + 1,
    });
  };

  handleDeleteClick = () => {
    this.setState(
      {
        showDeleteModal: false,
        templateRenderCount: this.state.templateRenderCount + 1,
        newTemplateName: "",
      },
      () => {
        this.deleteExportTemplate();
      }
    );
  };

  saveTemplate = () => {
    let params = {
      selectedTables: this.props.selectedTables,
      selectedCultures: this.props.selectedCultures,
      useLookback: this.props.useLookback,
      lookbackDays: this.props.lookbackDays,
    };
    let formData = {
      TemplateJson: JSON.stringify(params),
      TemplateName: this.state.newTemplateName,
    };
    this.state.oldTemplateName === ""
      ? this.saveNewTemplate(formData)
      : this.updateTemplate(formData);
    this.setState(
      {
        showNameModal: false,
        templateRenderCount: this.state.templateRenderCount + 1,
      },
      () => {
        this.getExportTemplates();
      }
    );
  };

  showDeleteModal = () => {
    this.setState({ showDeleteModal: true });
  };

  closeModal = () => {
    this.setState({
      showNameModal: false,
    });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  async getExportTemplates(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState({ exportTemplates: result.data }, () => {});
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "CustomExportTemplate",
      method: "get",
      data: {},
      isFormData: false,
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }

  async getSelectedExportTemplate(id: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        let props = JSON.parse(result.data.templateJson);
        let template: any = {};

        template.exportTemplateName = result.data.exportTemplateName;
        template.customExportTemplateId = result.data.customExportTemplateId;
        template.selectedTables = props.selectedTables;
        template.selectedCultures = props.selectedCultures;
        template.useLookback = props.lookbackDays > 0 ? true : false;
        template.exportTemplateName = result.data.exportTemplateName;
        template.startDate = new Date(
          new Date().setDate(new Date().getDate() - props.lookbackDays)
        );
        this.setState({ selectedTemplate: template }, () => {
          this.formatCultures(template);
          this.formatTables(template);
          this.props.populateTemplateData(template);
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: `CustomExportTemplate/${id}`,
      method: "get",
      data: {},
      isFormData: false,
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }

  async updateTemplate(formData: any): Promise<any> {
    let id = this.state.selectedTemplate.customExportTemplateId;
    const successCallback = (result: any): void => {
      if (result) {
        this.setState(
          {
            selectedTemplate: JSON.parse(result),
          },
          () => {
            this.saveTemplate();
          }
        );
      } else {
        this.setState({});
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({}, () => {
          setToaster({
            state: { error: true },
            message: error.response
              ? error.message
              : "Unknown Error: Check DTM Service",
          });
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: `CustomExportTemplate/${id}`,
      method: "put",
      data: { postData: JSON.stringify(formData) },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }

  async saveNewTemplate(formData: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result) {
        this.setState(
          {
            selectedTemplate: JSON.parse(result),
          },
          () => {
            this.saveTemplate();
          }
        );
      } else {
        this.setState({});
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({}, () => {
          setToaster({
            state: { error: true },
            message: error.response
              ? error.message
              : "Unknown Error: Check DTM Service",
          });
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: "CustomExportTemplate",
      method: "post",
      data: { postData: JSON.stringify(formData) },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }

  async deleteExportTemplate(): Promise<any> {
    let id = this.state.selectedTemplate.customExportTemplateId;
    const successCallback = (result: any): void => {
      this.setState(
        {
          selectedTemplate: null,
          showDeleteModal: false,
          newTemplateName: "",
        },
        () => {
          this.getExportTemplates();
          this.props.populateTemplateData(null);
        }
      );
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response
            ? error.message
            : "Unknown Error: Check DTM Service",
        });
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
        }
      }
    };
    MakeRequest({
      url: `CustomExportTemplate/`,
      method: "delete",
      data: { querystringParams: id.toString() },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }

  render() {
    let templateOptions: any = [];
    this.state.exportTemplates.forEach((option: any) => {
      templateOptions.push({
        label: option.templateName,
        value: option.customExportTemplateId,
      });
    });
    return (
      <div
        className="col-12"
        id="exortTemplateContainer"
        aria-labelledby="exortTemplateContainer"
      >
        <Modal show={this.state.showNameModal}>
          <Modal.Header>
            <Modal.Title>Save Export Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Template Name</p>
            <input
              type="text"
              value={this.state.newTemplateName}
              onChange={this.handleTemplateNameInputChange}
            ></input>
          </Modal.Body>
          <div className="modal-footer">
            <button
              onClick={this.handleModalSaveClick}
              className="btn btn-success btn-round"
            >
              Save
            </button>
            <button
              onClick={this.closeModal}
              className="btn btn-default btn-round"
            >
              Cancel
            </button>
            <br />
          </div>
        </Modal>
        <Modal show={this.state.showDeleteModal}>
          <Modal.Header>
            <Modal.Title>Delete Export Template</Modal.Title>
          </Modal.Header>
          <div className="modal-footer">
            <button
              onClick={this.closeDeleteModal}
              className="btn btn-success btn-round"
            >
              Cancel
            </button>
            <button
              onClick={this.handleDeleteClick}
              className="btn btn-default btn-round"
            >
              Delete
            </button>
            <br />
          </div>
        </Modal>

        <h5 className="title">Templates</h5>
        <Select
          id="templates"
          key={"inputNameKey_" + this.state.templateRenderCount}
          defaultValue={"Select..."}
          isMulti={false}
          name="colors"
          options={templateOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={this.handleTemplateSelect}
        />
        <br />

        <div className="row">
          <div className="container, col-12">
            <div className="col-lg-6">
              <button
                type="button"
                className="btn btn-success btn-round btn-left"
                disabled={
                  this.state.selectedTemplate ||
                  this.props.selectedTables.length ||
                  this.props.selectedCultures.length ||
                  this.props.useLookback
                    ? false
                    : true
                }
                onClick={this.handleSaveClick}
              >
                Save Template
              </button>
              <button
                type="button"
                className="btn btn-default btn-round btn-left"
                disabled={this.state.selectedTemplate ? false : true}
                onClick={this.showDeleteModal}
              >
                Delete Template
              </button>
            </div>
          </div>
        </div>

        <hr />
      </div>
    );
  }
}
