import React from "react";
import DataGrid from "../components/data-grid/data-grid";
import { Redirect } from "../utilities/utilities";

interface ISqlFilesProps {}
interface ISqlFilesState {
  renderCount: number;
  redirect: boolean;
}

class Settings extends React.Component<ISqlFilesProps, ISqlFilesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      renderCount: 0,
      redirect: false,
    };
    this.buildColumnList = this.buildColumnList.bind(this);
  }

  buildColumnList(): any {
    const cols = [
      {
        DataPropertyName: "DtmSettingId",
        ColumnAliasName: "Id",
        IsIdColumn: true,
        ShowColumn: true,
      },
      {
        DataPropertyName: "DtmSettingName",
        ColumnAliasName: "Name",
        InputType: "text",
        ShowColumn: true,
      },
      {
        DataPropertyName: "DtmSettingValue",
        ColumnAliasName: "Value",
        InputType: "text",
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "DtmSettingDescription",
        ColumnAliasName: "Info",
        InputType: "text",
        ShowColumn: true,
        IsInfoColumn: true,
        CanEdit: true,
      },
      {
        ColumnAliasName: "Actions",
        ShowColumn: true,
        IsActionsColumn: true,
      },
    ];
    return cols;
  }

  render() {
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Settings</h3>
            <div className="row">
              <div className="col-md-12">
                <DataGrid
                  key={"dataGrid_" + this.state.renderCount}
                  ColumnArray={this.buildColumnList()}
                  SelectAPI="Settings"
                  UpdateAPI="Settings"
                  GridName="settings"
                  MaxNumberOfRowsPerPage={50}
                  PrimaryKeyName="FileId"
                  SortByColumn="DtmSettingName"
                  LoadOnScroll={false}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect ? (
          <Redirect path="/login" isLogout={true} />
        ) : (
          <></>
        )}
      </>
    );
  }
}
export default Settings;
