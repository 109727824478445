import React from "react";
import DataGrid from "../components/data-grid/data-grid";
import { Redirect } from "../utilities/utilities";

interface ISqlFilesProps {}
interface ISqlFilesState {
  renderCount: number;
  redirect: boolean;
}

class SqlFiles extends React.Component<ISqlFilesProps, ISqlFilesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      renderCount: 0,
      redirect: false,
    };
    this.buildColumnList = this.buildColumnList.bind(this);
  }

  buildColumnList(): any {
    const cols = [
      {
        DataPropertyName: "FileId",
        ColumnAliasName: "File Id",
        IsIdColumn: true,
        ShowColumn: true,
      },
      {
        DataPropertyName: "FileName",
        ColumnAliasName: "Download",
        InputType: "text",
        ShowColumn: true,
        IsLink: true,
        APIPath: "ImportFileService/DownloadFile",
        QueryStringParams: ["FileId"],
      },
      {
        DataPropertyName: "CreatedDate",
        ColumnAliasName: "Date Created",
        InputType: "text",
        DataType: "datetime",
        ShowColumn: true,
      },
    ];
    return cols;
  }

  render() {
    return (
      <>
        <div className="section-light-table">
          <div className="container">
            <h3 className="title">Download Import Sql Files</h3>
            <div className="row">
              <div className="col-md-12">
                <DataGrid
                  key={"dataGrid_" + this.state.renderCount}
                  ColumnArray={this.buildColumnList()}
                  SelectAPI="ImportFileService/getimportfilesonscroll"
                  GridName="sqlFiles"
                  MaxNumberOfRowsPerPage={50}
                  PrimaryKeyName="FileId"
                  SortByColumn="FileId"
                  LoadOnScroll={true}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.redirect ? (
          <Redirect path="/login" isLogout={true} />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default SqlFiles;
