import React from "react";
import ValidationError from "./validation-error";

interface Props {
  Errors: any;
}

class ValidationSummary extends React.Component<Props> {
  render() {
    if (this.props.Errors != null && this.props.Errors.length > 0) {
      const errorList = this.props.Errors.map((error: any, counter: number) => (
        <ValidationError key={counter} Message={error.message} />
      ));
      return (
        <div className="container">
          <div className="row">
            <ul className="list-group-validation">{errorList}</ul>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default ValidationSummary;
