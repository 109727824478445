import React from "react";
import { AxiosError } from "axios";
import { MakeRequest } from "../../utilities/data-util";
import { sanitizeInput, Validator } from "../../validation/validator";
import ValidationSummary from "../../validation/validation-summary";
import {
  getAllFormElements,
  showValidationErrors,
  resetValidationErrors,
} from "../../utilities/utilities";
import { setToaster } from "../toaster/Toaster";
import { ToastContainer } from "react-toastify";
import DataGridRow from "../data-grid/data-grid-row";

interface DataGridRowCollectionProps {
  ColumnArray?: [];
  SelectAPI: string;
  UpdateAPI?: string | undefined;
  DeleteAPI?: string;
  MaxNumberOfRowsPerPage: number;
  GridName: string;
  OnDataSaved?: any;
  OnLoadingState: any;
  OnUpdateKeyValue: any;
  PrimaryKeyName: string;
  SortByColumn: string;
  LoadOnScroll: boolean;
}

interface DataGridRowCollectionState {
  data: [];
  noData: boolean;
  saveEnabled: boolean;
  formErrors: any;
  numberOfViewableColumns: number;
  files: [];
  currentItems: [];
  lastItemId: string;
  isLoading: boolean;
}

class DataGridRowCollection extends React.Component<
  DataGridRowCollectionProps,
  DataGridRowCollectionState
> {
  _isMounted = false;
  constructor(props: DataGridRowCollectionProps) {
    super(props);
    this.state = {
      data: [],
      noData: false,
      saveEnabled: false,
      formErrors: [],
      numberOfViewableColumns: 0,
      files: [],
      currentItems: [],
      lastItemId: "-1",
      isLoading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChangedRow = this.handleOnChangedRow.bind(this);
    this.handleOnEditMode = this.handleOnEditMode.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.buildDataObject = this.buildDataObject.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.deleteGridData = this.deleteGridData.bind(this);
    this.handleAPILinkClick = this.handleAPILinkClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  async loadGridData(): Promise<any> {
    const successCallback = (result: any): void => {
      if (
        result &&
        (result.ImportFiles === null || result.ImportFiles === undefined)
      ) {
        this.setState(
          {
            data: result,
            noData: false,
          },
          () => {
            this.addData(false);
          }
        );
      } else if (
        result &&
        result.ImportFiles &&
        result.ImportFiles.length > 0
      ) {
        this.setState({
          currentItems: result.ImportFiles,
          noData: false,
        });
      } else {
        this.setState({ isLoading: false, noData: true });
      }
      this.props.OnLoadingState(false);
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ noData: true }, () => {});
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { error: true },
            message:
              "Failed: Could not load data: Response status " +
              error.response.status,
          });
        }
        this.props.OnLoadingState(false);
      }
    };
    this.setState({ isLoading: true }, () => {
      if (this.props.LoadOnScroll) {
        MakeRequest({
          url: this.props.SelectAPI,
          method: "get",
          data: {
            querystringParams:
              "/" +
              this.state.lastItemId +
              "/" +
              this.props.MaxNumberOfRowsPerPage,
          },
          successCallback: successCallback,
          errorCallback: errorCallback,
        });
      } else {
        MakeRequest({
          url: this.props.SelectAPI,
          method: "get",
          data: {},
          successCallback: successCallback,
          errorCallback: errorCallback,
        });
      }
    });
  }
  updateGridData(data: any): void {
    const successCallback = (result: any): void => {
      if (result === "") {
        setToaster({
          state: { success: true },
          message: "Success: Grid Updated",
        });
        //update the parent key value
        this.props.OnUpdateKeyValue();
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ noData: true }, () => {});
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { error: true },
            message:
              "Failed: Could not update grid: Response status " +
              error.response.status,
          });
        } else {
          setToaster({
            state: { error: true },
            message: "Failed: Could not update grid: Unknown server error",
          });
        }
        this.props.OnLoadingState(false);
      }
    };
    MakeRequest({
      url: this.props.UpdateAPI,
      method: "put",
      data: { postData: data },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  deleteGridData(id: any): void {
    const successCallback = (result: any): void => {
      if (result === "") {
        setToaster({
          state: { success: true },
          message: "Success: Item Deleted",
        });
        //update the parent key value
        this.props.OnUpdateKeyValue();
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ noData: true }, () => {});
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { error: true },
            message:
              "Failed: Could not delete item: Response status " +
              error.response.status,
          });
        } else {
          setToaster({
            state: { error: true },
            message: "Failed: Could not update grid: Unknown server error",
          });
        }
        this.props.OnLoadingState(false);
      }
    };
    MakeRequest({
      url: this.props.DeleteAPI,
      method: "delete",
      data: { querystringParams: "?id=" + id },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  handleAPILinkClick(apiPath: string, fileName: string): void {
    const successCallback = (result: any): void => {
      if (result) {
        //this is how we tell the browser to download the file to disk
        var element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;base64," + result);
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        setToaster({
          state: { success: true },
          message: "Success",
        });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ noData: true }, () => {});
        if (error.response && error.response.status) {
          //do something with error
          setToaster({
            state: { error: true },
            message: "Failed: Response status " + error.response.status,
          });
        }
        this.props.OnLoadingState(false);
      }
    };
    MakeRequest({
      url: apiPath,
      method: "get",
      data: {},
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  getColumnHeaders(): any {
    const columns = [];
    if (this.props.ColumnArray) {
      for (let i = 0; i < this.props.ColumnArray.length; i++) {
        let column: any = this.props.ColumnArray[i];
        if (
          column.ShowColumn &&
          !column.IsInfoColumn &&
          !column.IsActionsColumn
        ) {
          columns.push(<th key={i}>{column.ColumnAliasName}</th>);
        }
        if (column.IsInfoColumn) {
          columns.push(
            <th key={i} className="text-left">
              {column.ColumnAliasName}
            </th>
          );
        }
        if (
          (this.props.UpdateAPI !== null &&
            this.props.UpdateAPI !== undefined &&
            this.props.UpdateAPI !== "") ||
          (this.props.DeleteAPI !== null &&
            this.props.DeleteAPI !== undefined &&
            this.props.DeleteAPI !== "")
        ) {
          if (column.IsActionsColumn) {
            columns.push(
              <th key={i} className="text-left">
                {column.ColumnAliasName}
              </th>
            );
          }
        }
      }
    }

    return columns;
  }
  handleOnEditMode(value: boolean): void {
    this.setState({ saveEnabled: value });
  }
  handleCancelClick(): void {
    //update the parent key value
    this.props.OnUpdateKeyValue();
  }
  handleDelete(row: any) {
    if (row !== null && row !== undefined && row.attributes !== undefined) {
      let rowId: any = row.attributes["data-rowid"].value;
      if (rowId !== null && rowId !== undefined) {
        this.deleteGridData(rowId);
      }
    }
  }
  doSelect(
    e: any,
    idIsInt: boolean,
    primaryKeyName: string,
    datavaluepropertyname: string,
    datavaluepropertyvalue: string,
    inputId: any
  ): any {
    let newRowDataState: any = [];
    //return the new data array with the updated
    newRowDataState = this.state.currentItems.map((item: any) => {
      let selectedLabel: string = "";
      let selectedValue: string = "";
      let options: HTMLOptionsCollection = e.target.options;

      for (var i = 0; i < options.length; i++) {
        if (
          options[i].value !== null &&
          options[i].value !== undefined &&
          options[i].value === e.target.value
        ) {
          selectedLabel = options[i].label;
          selectedValue = options[i].value;
        }
      }

      //do we need to parse values as numbers?
      if (idIsInt) {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          parseInt(item[primaryKeyName]) === parseInt(inputId)
        ) {
          item[datavaluepropertyname] = selectedLabel;
          item[datavaluepropertyvalue] = selectedValue;
        }
      } else {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          item[primaryKeyName] === inputId
        ) {
          item[datavaluepropertyname] = selectedLabel;
          item[datavaluepropertyvalue] = selectedValue;
        }
      }
      return item;
    });
    return newRowDataState;
  }
  doTextBox(
    e: any,
    idIsInt: boolean,
    primaryKeyName: string,
    inputId: any,
    cleanInput: string
  ): any {
    let newRowDataState: any = [];
    //return the new data array with the updated
    newRowDataState = this.state.currentItems.map((item: any) => {
      //do we need to parse values as numbers?
      if (idIsInt) {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          parseInt(item[primaryKeyName]) === parseInt(inputId)
        ) {
          item[e.target.attributes["data-textvaluepropertyname"].value] =
            cleanInput;
        }
      } else {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          item[primaryKeyName] === inputId
        ) {
          item[e.target.attributes["data-textvaluepropertyname"].value] =
            cleanInput;
        }
      }
      return item;
    });
    return newRowDataState;
  }
  doCheckbox(
    idIsInt: boolean,
    primaryKeyName: string,
    datavaluepropertyname: string,
    inputId: any,
    isChecked: boolean
  ): any {
    let newRowDataState: any = [];
    //return the new data array with the updated
    newRowDataState = this.state.currentItems.map((item: any) => {
      //do we need to parse values as numbers?
      if (idIsInt) {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          parseInt(item[primaryKeyName]) === parseInt(inputId)
        ) {
          item[datavaluepropertyname] = isChecked;
        }
      } else {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          item[primaryKeyName] === inputId
        ) {
          item[datavaluepropertyname] = isChecked;
        }
      }
      return item;
    });
    return newRowDataState;
  }
  handleOnChangedRow(e: any): void {
    //we do this on change to strip out any dangerous characters
    let cleanInput = "";
    //find the input value we need to update
    let primaryKeyName: string =
      e.target.attributes["data-primarykeyname"].value;

    let datavaluepropertyname: string = "";
    if (e.target.attributes["data-valuepropertyname"]) {
      datavaluepropertyname =
        e.target.attributes["data-valuepropertyname"].value;
    } else if (e.target.attributes["data-textvaluepropertyname"]) {
      datavaluepropertyname =
        e.target.attributes["data-textvaluepropertyname"].value;
    }

    let datavaluepropertyvalue: string = "";
    if (e.target.attributes["data-valuepropertyvalue"]) {
      datavaluepropertyvalue =
        e.target.attributes["data-valuepropertyvalue"].value;
    }

    //we need to know if the id value is a number or a string
    let idIsInt = false;
    let inputId: any = e.target.attributes["data-rowid"].value;
    let isRequired: any | null = e.target.attributes["data-required"]?.value;
    let errors: any;

    if (!isNaN(inputId)) {
      idIsInt = true;
    }

    let newRowDataState: any = [];

    if (e.target.tagName.toLowerCase() === "select") {
      if (isRequired && isRequired === "true") {
        //is this a required field
        if (!e.target.validity.valid) {
          let error = {
            fieldName: datavaluepropertyname,
            message: datavaluepropertyname + " is required",
          };
          errors.push(error);
          if (errors.length === 0) {
            resetValidationErrors(e.target);
          } else {
            this.setState({ formErrors: errors }, () => {
              showValidationErrors(this.state.formErrors);
            });
          }
        } else {
          //do select
          newRowDataState = this.doSelect(
            e,
            idIsInt,
            primaryKeyName,
            datavaluepropertyname,
            datavaluepropertyvalue,
            inputId
          );

          if (newRowDataState.length > 0) {
            this.setState({ data: newRowDataState });
          }
        }
      } else {
        //do select
        newRowDataState = this.doSelect(
          e,
          idIsInt,
          primaryKeyName,
          datavaluepropertyname,
          datavaluepropertyvalue,
          inputId
        );

        if (newRowDataState.length > 0) {
          this.setState({ data: newRowDataState });
        }
      }
    } else if (e.target.type === "checkbox") {
      let isChecked = e.target.checked;

      if (isRequired && isRequired === "true") {
        //is this a required field
        errors = Validator({ fields: e.target });
        if (errors.length === 0) {
          resetValidationErrors(e.target);
          if (errors.length === 0) {
            resetValidationErrors(e.target);

            //return the new data array with the updated
            newRowDataState = this.doCheckbox(
              idIsInt,
              primaryKeyName,
              datavaluepropertyname,
              inputId,
              isChecked
            );

            if (newRowDataState.length > 0) {
              this.setState({ data: newRowDataState });
            }
          } else {
            this.setState({ formErrors: errors }, () => {
              showValidationErrors(this.state.formErrors);
            });
          }
        }
      } else {
        //return the new data array with the updated
        newRowDataState = this.doCheckbox(
          idIsInt,
          primaryKeyName,
          datavaluepropertyname,
          inputId,
          isChecked
        );

        if (newRowDataState.length > 0) {
          this.setState({ data: newRowDataState });
        }
      }
    } else if (
      e.target.type === "text" ||
      e.target.type === "email" ||
      e.target.type === "number"
    ) {
      let sanitizeValue: any = e.target.attributes["data-sanitizeinput"];

      //unless specified otherwise the default position is to sanitize all user input
      //if we don't want to sanitize a value then add the SanitizeInput = false property to the column in the calling component
      if (sanitizeValue === null || sanitizeValue === undefined) {
        cleanInput = sanitizeInput(e.target.value, e.target.type);
      } else if (
        sanitizeValue.value !== null &&
        sanitizeValue.value !== undefined &&
        sanitizeValue.value === "true"
      ) {
        cleanInput = sanitizeInput(e.target.value, e.target.type);
      } else {
        cleanInput = e.target.value;
      }

      //is this a required field
      errors = Validator({ fields: e.target });
      if (errors.length === 0) {
        resetValidationErrors(e.target);

        //return the new data array with the updated
        newRowDataState = this.doTextBox(
          e,
          idIsInt,
          primaryKeyName,
          inputId,
          cleanInput
        );
        if (newRowDataState.length > 0) {
          this.setState({ data: newRowDataState });
        }
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  }
  handleSubmit(e: any): void {
    if (e !== null && e !== undefined) {
      e.preventDefault();
      //there is inbuilt validation in some browsers
      //we want to override this and provide a custom validation solution
      let form = document.getElementById(this.props.GridName);
      let allFormElements: Element[] = getAllFormElements({
        element: form ? form : e,
      });
      let errors = Validator({ fields: allFormElements });

      if (errors.length === 0) {
        this.setState({ formErrors: [] });
        let dataToUpdate: any = this.buildDataObject(allFormElements);
        if (
          dataToUpdate !== null &&
          dataToUpdate !== undefined &&
          dataToUpdate.length > 0
        ) {
          dataToUpdate.forEach((row: any) => {
            this.updateGridData(row);
          });
        }
        //optional send saved data to calling component for
        //further bespoke operations
        if (
          this.props.OnDataSaved !== null &&
          this.props.OnDataSaved !== undefined
        ) {
          this.props.OnDataSaved(dataToUpdate);
        }
      } else {
        //show notification
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  }
  buildDataObject(inputCollection: Element[]): any {
    let dataToUpdate: any = [];
    inputCollection.forEach((input: any) => {
      let inputPrimaryKeyName: any =
        input.attributes["data-primarykeyname"].value;
      let inputRowId: any = input.attributes["data-rowid"].value;
      //get the data object from the state
      let dataObj: any = this.state.data;
      if (this.props.LoadOnScroll) {
        dataObj = this.state.currentItems;
      }
      let updatedRow: any | null = dataObj.find((row: any) => {
        let rowFound: any;
        //make sure we haven't already added the row to dataToUpdate
        rowFound = dataToUpdate.find(
          (r: any) => r[inputPrimaryKeyName] === row[inputPrimaryKeyName]
        );
        if (rowFound === null || rowFound === undefined) {
          //we need to know if the id value is a number or a string
          if (!isNaN(row[inputPrimaryKeyName])) {
            if (row[inputPrimaryKeyName] === parseInt(inputRowId)) {
              return row;
            }
          } else {
            if (row[inputPrimaryKeyName] === inputRowId) {
              return row;
            }
          }
        }
      });

      if (updatedRow !== null && updatedRow !== undefined) {
        dataToUpdate.push(updatedRow);
      }
    });
    return dataToUpdate;
  }
  handleScroll = (e: any): void => {
    if (this.props.LoadOnScroll) {
      let scroller: any = document.getElementById("scrollable");
      let overallScrollHeight: number;
      if (scroller && scroller.lastChild && scroller.lastChild !== undefined) {
        let rowHeight: number = scroller.lastChild.offsetHeight;
        overallScrollHeight = Math.round(
          window.innerHeight + document.documentElement.scrollTop + rowHeight
        );
      } else {
        overallScrollHeight = Math.round(
          window.innerHeight + document.documentElement.scrollTop
        );
      }

      if (
        (scroller &&
          overallScrollHeight < document.documentElement.offsetHeight) ||
        this.state.isLoading
      ) {
        return;
      }

      if (
        this.state.currentItems != null &&
        this.state.currentItems.length > 0 &&
        this.state.data.length > 0
      ) {
        //calculate the highest Id value in the current data
        let maxIdValue: number = 0;
        this.state.currentItems.forEach((item: any) => {
          if (item[this.props.PrimaryKeyName] > maxIdValue) {
            maxIdValue = item[this.props.PrimaryKeyName];
          }
        });
        if (maxIdValue > 0) {
          this.setState({ lastItemId: maxIdValue.toString() }, () => {
            this.loadGridData().then((result) => {
              if (result && result !== undefined && result.length > 0) {
                this.addData(true);
              }
            });
          });
        }
        //sort the data
        const sortedData = this.state.currentItems.sort((a, b) => {
          if (a[this.props.SortByColumn] < b[this.props.SortByColumn])
            return -1;
          if (a[this.props.SortByColumn] > b[this.props.SortByColumn]) return 1;
          return 0;
        });

        if (sortedData && sortedData.length > 0) {
          this.setState({
            currentItems: sortedData,
          });
        }
      }
    }
  };

  addData = (clearData: boolean): void => {
    //add the new data to existing data array
    let updatedData: any = [];
    if (
      this.state.data &&
      this.state.data.length > 0 &&
      this.state.currentItems.length > 0
    ) {
      updatedData = this.state.currentItems;
      this.state.data.forEach((item: any) => {
        const found = this.state.currentItems.find(
          (element: any) => element === item
        );
        if (found === null || found === undefined) {
          updatedData.push(item);
        }
      });
    } else if (this.state.data.length > 0) {
      updatedData = this.state.data;
    }

    if (clearData) {
      if (updatedData && updatedData.length > 0) {
        this.setState({
          data: [],
          currentItems: updatedData,
        });
      }
    } else {
      if (updatedData && updatedData.length > 0) {
        this.setState({
          currentItems: updatedData,
        });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  componentDidMount(): void {
    this.loadGridData().then(() => {
      //set number of viewable columns
      let viewableColumns: number = 0;
      if (this.props.ColumnArray) {
        this.props.ColumnArray.forEach((column: any) => {
          if (
            column.ShowColumn !== null &&
            column.ShowColumn !== undefined &&
            column.ShowColumn
          ) {
            viewableColumns++;
          }
        });
        this.setState({
          numberOfViewableColumns: viewableColumns,
        });
      }
    });

    //set up scroller
    if (this.props.LoadOnScroll) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }
  render() {
    if (this.state.currentItems != null && this.state.currentItems.length > 0) {
      const rows = this.state.currentItems.map((row: any, index) => (
        <DataGridRow
          key={index}
          ColumnArray={this.props.ColumnArray}
          RowData={row}
          UpdateAPI={this.props.UpdateAPI}
          DeleteAPI={this.props.DeleteAPI}
          OnChangedRow={this.handleOnChangedRow}
          OnEdit={this.handleOnEditMode}
          OnDelete={this.handleDelete}
          OnAPILinkClick={this.handleAPILinkClick}
        />
      ));
      return (
        <form id={this.props.GridName} onSubmit={this.handleSubmit}>
          <h5>
            <small>{this.state.data.length} results</small>
          </h5>
          <div className="table-responsive">
            <div>
              <ValidationSummary Errors={this.state.formErrors} />
              <table className="table">
                <thead>
                  <tr>{this.getColumnHeaders()}</tr>
                </thead>
                <tbody id="scrollable">{rows}</tbody>
                {this.props.UpdateAPI ? (
                  <tfoot>
                    <tr>
                      <td colSpan={this.state.numberOfViewableColumns}>
                        {this.state.saveEnabled ? (
                          <>
                            <button className="btn btn-success btn-round">
                              Save
                            </button>
                            <button
                              id="cancel"
                              className="btn btn-default btn-round"
                              onClick={this.handleCancelClick}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-success btn-round"
                            disabled
                          >
                            Save
                          </button>
                        )}
                      </td>
                    </tr>
                  </tfoot>
                ) : null}
              </table>
            </div>

            <ToastContainer autoClose={5000} position="bottom-right" />
          </div>
        </form>
      );
    } else {
      return (
        <>
          {this.state.noData && (
            <div className="notifications">
              <div className="alert alert-info">
                <div className="container">
                  <span>No Data Yet. </span>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}

export default DataGridRowCollection;
