import React from "react";

interface Props {
  selectName: string;
  id: string;
  options: any[];
  datarowid: string;
  dataRequired: boolean;
  dataprimarykeyname: string;
  datavaluepropertyname: string;
  datavaluepropertyvalue: string;
  datavalidationname: string;
  datasanitizeinput: any;
  onChange: any;
  value: any;
}

interface State {
  renderCount: number;
  selectOptions: any[];
  selectedItemValue: string;
}

class GridSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      renderCount: 0,
      selectOptions: [],
      selectedItemValue: "",
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  buildOptions = (): void => {
    let data: any = this.props.options;
    let options: any[] = [];
    if (data !== null && data !== undefined) {
      data.forEach((element: any) => {
        let option: any;
        if (element.value === this.props.value) {
          option = (
            <option key={element.value} value={element.value}>
              {element.label}
            </option>
          );
          this.setState({ selectedItemValue: element.value });
        } else {
          option = (
            <option key={element.value} value={element.value}>
              {element.label}
            </option>
          );
        }

        options.push(option);
      });
    }

    this.setState({ selectOptions: options });
  };

  handleOnChange = (e: any): void => {
    this.props.onChange(e);
    this.setState({ selectedItemValue: e.target.value });
  };

  componentDidMount(): void {
    this.buildOptions();
    this.setState({ renderCount: this.state.renderCount + 1 });
  }

  render() {
    return (
      <>
        <select
          key={"select_" + this.props.id + "_" + this.state.renderCount}
          name={this.props.selectName}
          id={this.props.id}
          className="form-control"
          onChange={this.handleOnChange}
          data-rowid={this.props.datarowid}
          data-required={this.props.dataRequired}
          data-primarykeyname={this.props.dataprimarykeyname}
          data-valuepropertyname={this.props.datavaluepropertyname}
          data-valuepropertyvalue={this.props.datavaluepropertyvalue}
          data-validationname={this.props.datavalidationname}
          data-sanitizeinput={this.props.datasanitizeinput}
          value={this.state.selectedItemValue}
        >
          {this.state.selectOptions}
        </select>
      </>
    );
  }
}

export default GridSelect;
